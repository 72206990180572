import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "state/profileSlice";

// MUI
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, Stack, TextField, Tooltip } from "@mui/material";

// third party libraries
import { Controller, useForm } from "react-hook-form";

// custom components and constants:
import Button from "components/Buttons/Button";
import Text from "components/Text/Text";

// api
import { updateUser } from "api/endpoints/shopApi";

// styles
import MobileInput from "components/ui/MobileInput";
import styles from "./AccountProfileForm.module.scss";

function AccountProfileForm() {
  // variables needed from Redux:
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: profile?.profile.first_name,
      last_name: profile?.profile.last_name,
      phone_country_code: profile?.profile.phone_country_code
        ? profile?.profile.phone_country_code
        : "+1",
      phone_number: profile?.profile.phone_number,
      accepts_mobile_terms: profile?.profile.accepts_mobile_terms
        ? profile?.profile.accepts_mobile_terms
        : false,
    },
  });

  const [isEditMode, setIsEditMode] = useState(true);
  const [loading, setLoading] = useState(false);

  const withPhoneNumber = useMemo(
    () => profile?.profile.phone_number && profile?.profile.phone_country_code,
    [profile]
  );

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const updatedUser = {
        ...data,
      };
      const response = await updateUser(updatedUser);
      dispatch(setProfile(response.data));
      setIsEditMode(false);
      setLoading(false);
      toast.success("Success! Your account has been updated.");
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  //
  const handleCancel = () => {
    reset({
      first_name: profile?.profile.first_name,
      last_name: profile?.profile.last_name,
      phone_country_code: profile?.profile.phone_country_code
        ? profile?.profile.phone_country_code
        : "+1",
      phone_number: profile?.profile.phone_number,
      accepts_mobile_terms: profile?.profile.accepts_mobile_terms
        ? profile?.profile.accepts_mobile_terms
        : false,
    });
    setIsEditMode(false);
  };

  return (
    <form className={styles.accountProfileForm}>
      <Stack className={styles.sideBySide}>
        <Box>
          <Text className={styles.label}>First Name</Text>
          <Controller
            name="first_name"
            control={control}
            defaultValue={profile?.profile.first_name}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled={!isEditMode}
                error={!!errors.first_name}
                helperText={errors.first_name ? "This is required." : null}
              />
            )}
          />
        </Box>
        <Box>
          <Text className={styles.label}>Last Name</Text>
          <Controller
            name="last_name"
            control={control}
            defaultValue={profile?.profile.last_name}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled={!isEditMode}
                error={!!errors.last_name}
                helperText={errors.last_name ? "This is required." : null}
              />
            )}
          />
        </Box>
      </Stack>

      <Text className={styles.label}>Email</Text>
      <div className={styles.emailWrapper}>
        <TextField
          value={profile?.profile.email}
          name="email"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          disabled
          className={styles.emailInput}
        />
        <Tooltip
          title="This email address is used as your primary login and is not editable here. To request a change, please contact support."
          placement="top"
        >
          <IconButton className={styles.infoButton}>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </div>

      <div
        style={{
          marginTop: "2rem",
          borderTop: "1px solid #0000001F",
          paddingTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <MobileInput
          disabled={withPhoneNumber || !isEditMode}
          control={control}
          reset={reset}
          isEditMode={isEditMode}
          countryDefault={profile?.profile.phone_country_code}
        />
      </div>

      {isEditMode ? (
        <div className={styles.buttonGroup}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles.saveCancelButtons}
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            disabled={loading}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            className={styles.saveCancelButtons}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={styles.editButton}
          onClick={() => setIsEditMode(true)}
        >
          Edit Information
        </Button>
      )}
    </form>
  );
}

export default AccountProfileForm;
