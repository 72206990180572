/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
/* eslint-disable import/no-cycle */
import {
  getShopSettings,
  updateShopAutomaticPayments,
  createShopSettings,
  updateCustomerOrdersShippingMethod,
} from "api/endpoints/shopApi";
import { setShop, pushNotificationReducer } from "./profileSlice";

export const setAutomaticPaymentsThunk = createAsyncThunk(
  "shopSettings/shopAutomaticPayments",
  async (value, thunkAPI) => {
    try {
      const response = await updateShopAutomaticPayments(value);

      // Show plain toast notification
      toast.success(
        value ? "Automatic payments enabled" : "Automatic payments disabled"
      );

      // Update the profile state with the new config flag
      thunkAPI.dispatch(
        setShop({
          ...thunkAPI.getState().profile.shop,
          config_flags: {
            ...thunkAPI.getState().profile.shop.config_flags,
            SHOP_AUTO_PAY_CUSTOMER_ORDERS: response.data.enabled,
          },
        })
      );

      return {
        enabled: response.data.enabled,
      };
    } catch (error) {
      // Show plain error toast
      toast.error("Failed to update automatic payment setting");

      throw thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const setCustomerOrdersShippingMethodThunk = createAsyncThunk(
  "shopSettings/setCustomerOrdersShippingMethod",
  async (enabled, thunkAPI) => {
    try {
      const response = await updateCustomerOrdersShippingMethod({ enabled });

      // Get the current shop settings to access shipping costs
      const state = thunkAPI.getState();
      const shopSettings = state.shopSettings.settings.values;

      // Determine which shipping cost to display
      const shippingCost = enabled
        ? shopSettings?.expedited_shipping_cost
        : shopSettings?.standard_shipping_cost;

      // Show plain toast notification with shipping cost
      toast.success(
        `Shipping method set to ${enabled ? "expedited" : "standard"}`
      );

      // Update the profile state with the new config flag
      thunkAPI.dispatch(
        setShop({
          ...state.profile.shop,
          config_flags: {
            ...state.profile.shop.config_flags,
            SHOP_CUSTOMER_ORDERS_USE_EXPEDITED_SHIPPING: enabled
              ? "True"
              : "False",
          },
        })
      );

      return response.data;
    } catch (error) {
      // Show plain error toast
      toast.error("Failed to update shipping method");

      throw thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getShopSettingsThunk = createAsyncThunk(
  "shopSettings/getShopSettings",
  async (value, thunkAPI) => {
    try {
      const response = await getShopSettings();
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createShopSettingsThunk = createAsyncThunk(
  "shopSettings/createShopSettings",
  async (value, thunkAPI) => {
    try {
      const response = await createShopSettings(value);
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  shopAutomaticPayments: {
    enabled: false,
    loading: false,
    error: false,
  },
  settings: {
    values: {},
    loading: true,
    error: false,
    errorMessage: "",
  },
};

export const shopSettingsSlice = createSlice({
  name: "shopSettings",
  initialState,
  reducers: {
    setAutomaticPayments: (state, action) => {
      state.shopAutomaticPayments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAutomaticPaymentsThunk.pending, (state) => {
        state.shopAutomaticPayments.loading = true;
        state.shopAutomaticPayments.error = false;
      })
      .addCase(setAutomaticPaymentsThunk.rejected, (state) => {
        state.shopAutomaticPayments.loading = false;
        state.shopAutomaticPayments.error = true;
      })
      .addCase(setAutomaticPaymentsThunk.fulfilled, (state, action) => {
        state.shopAutomaticPayments = action.payload;
        state.shopAutomaticPayments.loading = false;
        state.shopAutomaticPayments.error = false;
      })
      .addCase(getShopSettingsThunk.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          [state.settings.values] = action.payload;
        }
        state.settings.loading = false;
        state.settings.error = false;
      })
      .addCase(getShopSettingsThunk.pending, (state, action) => {
        state.settings.loading = true;
        state.settings.error = false;
      })
      .addCase(getShopSettingsThunk.rejected, (state, action) => {
        state.settings.loading = false;
        state.settings.error = true;
      })
      .addCase(createShopSettingsThunk.fulfilled, (state, action) => {
        state.settings.loading = false;
        state.settings.error = false;
        state.settings.values = action.payload;
      })
      .addCase(createShopSettingsThunk.pending, (state, action) => {
        state.settings.loading = true;
        state.settings.error = false;
      })
      .addCase(createShopSettingsThunk.rejected, (state, action) => {
        state.settings.loading = false;
        state.settings.error = true;
        state.settings.errorMessage = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setAutomaticPayments } = shopSettingsSlice.actions;

export const selectShopSettings = (state) => state.shopSettings.settings;

export default shopSettingsSlice.reducer;
