import { SvgIcon } from "@mui/material";
import React from "react";

function HeartIcon({ size = 40, isActive }) {
  return isActive ? (
    <SvgIcon viewBox="0 0 27 28" style={{ width: size, height: size }}>
      <path
        d="M15.1212 20.6L14.08 19.6399C12.6918 18.3739 11.5465 17.2893 10.6441 16.3861C9.7418 15.4829 9.03033 14.6792 8.50975 13.9751C7.98917 13.271 7.62823 12.6274 7.42694 12.0442C7.22565 11.461 7.125 10.8636 7.125 10.2519C7.125 8.98599 7.54841 7.91918 8.39522 7.05151C9.24204 6.18384 10.2832 5.75 11.5187 5.75C12.1989 5.75 12.8584 5.89935 13.4969 6.19806C14.1355 6.49677 14.6769 6.91638 15.1212 7.4569C15.5654 6.91638 16.1068 6.49677 16.7454 6.19806C17.384 5.89935 18.0434 5.75 18.7236 5.75C19.9591 5.75 21.0003 6.18384 21.8471 7.05151C22.6939 7.91918 23.1173 8.98599 23.1173 10.2519C23.1173 10.8636 23.0201 11.4539 22.8258 12.0228C22.6314 12.5918 22.274 13.2248 21.7534 13.9218C21.2328 14.6188 20.5179 15.426 19.6086 16.3434C18.6993 17.2609 17.5367 18.3739 16.1207 19.6825L15.1212 20.6Z"
        fill="#D56F5B"
      />
      <path
        d="M13.5026 23.2416L12.1276 22.0041C10.2943 20.3724 8.78179 18.9745 7.59013 17.8103C6.39846 16.6461 5.45888 15.6103 4.77138 14.7028C4.08388 13.7953 3.60721 12.9657 3.34138 12.2141C3.07554 11.4624 2.94263 10.6924 2.94263 9.90406C2.94263 8.2724 3.50179 6.8974 4.62013 5.77906C5.73846 4.66073 7.11346 4.10156 8.74513 4.10156C9.64346 4.10156 10.5143 4.29406 11.3576 4.67906C12.201 5.06406 12.916 5.6049 13.5026 6.30156C14.0893 5.6049 14.8043 5.06406 15.6476 4.67906C16.491 4.29406 17.3618 4.10156 18.2601 4.10156C19.8918 4.10156 21.2668 4.66073 22.3851 5.77906C23.5035 6.8974 24.0626 8.2724 24.0626 9.90406C24.0626 10.6924 23.9343 11.4532 23.6776 12.1866C23.421 12.9199 22.9489 13.7357 22.2614 14.6341C21.5739 15.5324 20.6297 16.5728 19.4289 17.7553C18.228 18.9378 16.6926 20.3724 14.8226 22.0591L13.5026 23.2416ZM13.5026 20.5741C15.2076 19.0524 16.6101 17.7553 17.7101 16.6828C18.8101 15.6103 19.6855 14.6753 20.3364 13.8778C20.9872 13.0803 21.441 12.3699 21.6976 11.7466C21.9543 11.1232 22.0826 10.5091 22.0826 9.90406C22.0826 8.8224 21.716 7.9149 20.9826 7.18156C20.2493 6.44823 19.3418 6.08156 18.2601 6.08156C17.6185 6.08156 17.018 6.21448 16.4589 6.48031C15.8997 6.74615 15.4185 7.1174 15.0151 7.59406L14.0526 8.72156H12.9526L11.9901 7.59406C11.5868 7.1174 11.0964 6.74615 10.5189 6.48031C9.94138 6.21448 9.35013 6.08156 8.74513 6.08156C7.66346 6.08156 6.75596 6.44823 6.02263 7.18156C5.28929 7.9149 4.92263 8.8224 4.92263 9.90406C4.92263 10.5091 5.04179 11.1095 5.28013 11.7053C5.51846 12.3011 5.95388 12.9932 6.58638 13.7816C7.21888 14.5699 8.08971 15.5049 9.19888 16.5866C10.308 17.6682 11.7426 18.9974 13.5026 20.5741Z"
        fill="#5E5E5E"
      />
    </SvgIcon>
  ) : (
    <SvgIcon viewBox="0 0 27 28" style={{ width: size, height: size }}>
      <path
        d="M13.5026 23.2416L12.1276 22.0041C10.2943 20.3724 8.78179 18.9745 7.59013 17.8103C6.39846 16.6461 5.45888 15.6103 4.77138 14.7028C4.08388 13.7953 3.60721 12.9657 3.34138 12.2141C3.07554 11.4624 2.94263 10.6924 2.94263 9.90406C2.94263 8.2724 3.50179 6.8974 4.62013 5.77906C5.73846 4.66073 7.11346 4.10156 8.74513 4.10156C9.64346 4.10156 10.5143 4.29406 11.3576 4.67906C12.201 5.06406 12.916 5.6049 13.5026 6.30156C14.0893 5.6049 14.8043 5.06406 15.6476 4.67906C16.491 4.29406 17.3618 4.10156 18.2601 4.10156C19.8918 4.10156 21.2668 4.66073 22.3851 5.77906C23.5035 6.8974 24.0626 8.2724 24.0626 9.90406C24.0626 10.6924 23.9343 11.4532 23.6776 12.1866C23.421 12.9199 22.9489 13.7357 22.2614 14.6341C21.5739 15.5324 20.6297 16.5728 19.4289 17.7553C18.228 18.9378 16.6926 20.3724 14.8226 22.0591L13.5026 23.2416ZM13.5026 20.5741C15.2076 19.0524 16.6101 17.7553 17.7101 16.6828C18.8101 15.6103 19.6855 14.6753 20.3364 13.8778C20.9872 13.0803 21.441 12.3699 21.6976 11.7466C21.9543 11.1232 22.0826 10.5091 22.0826 9.90406C22.0826 8.8224 21.716 7.9149 20.9826 7.18156C20.2493 6.44823 19.3418 6.08156 18.2601 6.08156C17.6185 6.08156 17.018 6.21448 16.4589 6.48031C15.8997 6.74615 15.4185 7.1174 15.0151 7.59406L14.0526 8.72156H12.9526L11.9901 7.59406C11.5868 7.1174 11.0964 6.74615 10.5189 6.48031C9.94138 6.21448 9.35013 6.08156 8.74513 6.08156C7.66346 6.08156 6.75596 6.44823 6.02263 7.18156C5.28929 7.9149 4.92263 8.8224 4.92263 9.90406C4.92263 10.5091 5.04179 11.1095 5.28013 11.7053C5.51846 12.3011 5.95388 12.9932 6.58638 13.7816C7.21888 14.5699 8.08971 15.5049 9.19888 16.5866C10.308 17.6682 11.7426 18.9974 13.5026 20.5741Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default HeartIcon;
