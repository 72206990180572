import { Box, CircularProgress } from "@mui/material";
import BrandSetupWrapper from "components/BrandSetup/BrandSetupWrapper";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  createSetupIntent,
  getDiscount,
  getProducts,
  getUser,
  orderBrandPlate,
  QUERY,
} from "api";
import { getProductGroups } from "api/endpoints/brandSetupApi";
import queryClient from "api/queryClient";
import LoadingGif from "assets/images/brand-setup/brand-setup-loading.gif";
import BrandSetupGroupAccordion from "components/BrandSetup/BrandSetupGroupAccordion";
import BrandSetupSteps from "components/BrandSetup/BrandSetupSteps";
import ExclamationPointIcon from "components/Icons/ExclamationIcon";
import WandIcon from "components/Icons/WandIcon";
import UpdatePaymentStripe from "components/Modals/UpdatePaymentStripe";
import ModalWrapper from "components/ModalWrapper";
import {
  AddItemToCartError,
  ClearCartError,
} from "components/Notifications/Notifications";
import Popper from "components/Popper";
import { CATEGORY, PAGE, PRODUCT_TYPE } from "constants/brandPlate";
import { SMALL } from "constants/breakpoints";
import {
  BRAND_SETUP_STEPS,
  CART_TYPE_BRAND_PLATE,
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  CUSTOM_PRODUCT_STATUS_IN_PROGRESS,
  PRODUCT_TYPE_BRAND_PLATE,
} from "constants/constants";
import { PLAN_INFO } from "constants/plans";
import { useAnalytics, useCart } from "hooks";
import { useShopMutations } from "hooks/mutations/useShopMutations";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import ROUTE_PATHS from "routes/routePaths";
import { setCurrentOrder } from "state/brandPlateSlice";
import { clearCart, IDLE } from "state/cartSlice";
import cn from "utils/cn";
import CheckoutConfirmPayment from "views/CheckoutPaymentView/components/CheckoutConfirmPayment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import styles from "./BrandSetupReviewView.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

const generatorMessages = [
  "Creating your new products",
  "Uploading your logo",
  "Building your product line",
  "Sending to customizer!",
];

function BrandSetupReviewView() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { shop } = useSelector((state) => state.profile);
  const [isOpen, setIsOpen] = useState(false);
  const { add, cart } = useCart();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const planId = shop.plan;

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const { updateShopMutation } = useShopMutations();
  const [isConfirmBoxInView, setIsConfirmBoxInView] = useState(false);
  const [setupCardModal, setSetupCardModal] = useState(false);
  const confirmBoxRef = useRef(null);

  const shouldBlock = useCallback(
    ({ nextLocation }) => !nextLocation.pathname.includes("customize"),
    []
  );

  const blocker = useBlocker(shouldBlock);

  // Handle the beforeunload event to trigger the blocker modal
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
      return blocker.state === "blocked";
    };

    window.addEventListener("beforeunload", handleBeforeUnload, {
      passive: true,
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [blocker]);

  useEffect(() => {
    // clear cart when on this page
    if (cart.status === IDLE && cart.items.length > 0) {
      dispatch(clearCart());
      blocker?.reset?.();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => {
        if (prevIndex === 3) {
          clearInterval(interval);
          return prevIndex;
        }
        return prevIndex === generatorMessages.length - 1 ? 0 : prevIndex + 1;
      });
    }, 4500);

    return () => clearInterval(interval);
  }, [generatorMessages.length]);

  useEffect(() => {
    const prevUrl = sessionStorage.getItem("prevUrl");
    if (prevUrl) {
      // Retrieve the saved scroll position
      const prevScrollPosition = sessionStorage.getItem("prevScrollPosition");

      // Scroll to the previous position after navigation
      setTimeout(() => {
        window.scrollTo(0, parseInt(prevScrollPosition || 0, 10));
      }, 0);

      // Clean up after navigation
      sessionStorage.removeItem("prevUrl");
      sessionStorage.removeItem("prevScrollPosition");
    }
  }, []);

  const { data: productGroups, isLoading: loadingGroups } = useQuery(
    ["productGroups"],
    () => getProductGroups(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!loadingGroups) {
          setIsConfirmBoxInView(entry.isIntersecting);
        }
      },
      { threshold: 0.1 }
    );

    if (confirmBoxRef.current) {
      observer.observe(confirmBoxRef.current);
    }

    return () => {
      if (confirmBoxRef.current) {
        observer.unobserve(confirmBoxRef.current);
      }
    };
  }, [confirmBoxRef, loadingGroups]);

  const onNext = async () => {
    setIsOpen(true);
    analytics.sendEvent(analytics.BRAND_SETUP_REVIEW_CONFIRMATION);
  };

  // from what i understand this filter is to fetch a free product
  const { data: products } = useQuery(
    ["products", shop.plan],
    () => getProducts(CATEGORY, PRODUCT_TYPE, PAGE),
    {
      enabled: !!shop.plan,
    }
  );

  const [clientSecret, setClientSecret] = useState(null);
  const [clientSecretCard, setClientSecretCard] = useState(null);
  const [nextActionType, setNextActionType] = useState(null);

  function createBrandPlateCartItem() {
    const brandPlateProduct = products?.data?.results[0];
    return {
      type: CART_TYPE_BRAND_PLATE,
      item: {
        id: brandPlateProduct.id,
        sku: brandPlateProduct.sku,
        quantity: 1,
        product: {
          ...brandPlateProduct,
          product_type: PRODUCT_TYPE_BRAND_PLATE,
        },
      },
    };
  }

  const onScrollBottom = () => {
    const confirmBox = document.getElementById("confirmBox");
    if (confirmBox) {
      confirmBox.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSuccess = async () => {};

  const setSetupIntent = async () => {
    await getUser();
    const response = await createSetupIntent(PLAN_INFO[shop.plan].price);
    setClientSecretCard(response.data.client_secret);
  };

  const onApproveBrandSetup = async (bypass) => {
    setIsLoading(true);

    if (!shop.has_payment_method && !bypass && shop?.brand_plate_created) {
      await setSetupIntent();
      setSetupCardModal(true);
      setIsLoading(false);
      return;
    }

    if (
      cart.status === IDLE &&
      cart.items.length > 0 &&
      cart.type !== CART_TYPE_BRAND_PLATE
    ) {
      toast.error(ClearCartError);
    }
    const item = createBrandPlateCartItem();
    try {
      await add(item, CART_TYPE_BRAND_PLATE);
    } catch (err) {
      toast.error(() => AddItemToCartError(err));
      setIsLoading(false);
    }
  };

  const onSuccessUpdateCard = async () => {
    setSetupCardModal(false);
    setIsLoading(true);
    await queryClient.refetchQueries([QUERY.getUser]);
    await onApproveBrandSetup(true);
  };

  const onErrorUpdateCard = async () => {
    setClientSecretCard("");
    await setSetupIntent();
  };

  async function placeOrder() {
    // this needs to be the free product
    const brandPlateProduct = products?.data?.results[0];
    const orderData = {
      use_genny_images: false,
    };

    const response = await orderBrandPlate(
      brandPlateProduct?.color_name,
      cart,
      orderData
    );

    if (response.status === 200) {
      dispatch(setCurrentOrder(response.data));
      if (response.data.client_secret) {
        setClientSecret(response.data.client_secret);
        setNextActionType(response.data.next_action?.type || null);
        setIsLoading(false);
      }
      // do we need to trigger payment?
    } else {
      setIsLoading(false);
      toast.error(`Error placing Brand Plate order`);
    }

    setIsLoading(false);
    return response;
  }

  // const publishAllFiles = (files, orderId) =>
  //   Promise.all(
  //     files.map((file) => publishFile({ fileName: file.fileName, orderId }))
  //   );

  useEffect(() => {
    // this triggers once cart is updated from the backend
    if (
      cart.status === IDLE &&
      cart.items.length > 0 &&
      cart.confirmation_url === ""
    ) {
      if (cart.type === CART_TYPE_BRAND_PLATE) {
        setIsLoading(true);
        placeOrder().then(async (response) => {
          setIsLoading(false);
          if (response.status === 200) {
            // ask @doug if this is needed - it's used in the old implementation
            // if (hasPrintReadyLogos) {
            //   publishAllFiles(fileNames, response.data.id).then(() => {
            //     dispatch(getBrandPlateOrdersAssetsAsync(response.data.id));
            //   });
            // }

            await updateShopMutation.mutateAsync({
              id: shop.id,
              brand_setup_step: BRAND_SETUP_STEPS.SUCCESS,
            });
            analytics.sendEvent(analytics.BRAND_SETUP_APPROVED);
            dispatch(clearCart());
          }
        });
      }
    }
  }, [cart]);

  const renderGroups = () => {
    if (productGroups?.data) {
      return productGroups?.data.map((group, index) => (
        <BrandSetupGroupAccordion index={index} key={group?.id} group={group} />
      ));
    }

    return <Box className={styles.brandSetupLoading} />;
  };

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const renderBackComponent = () => (
    <Box
      onClick={async () => {
        await updateShopMutation.mutateAsync({
          id: shop.id,
          brand_setup_step: BRAND_SETUP_STEPS.UPLOAD,
        });
      }}
      mb={1}
      display="flex"
      alignItems="center"
      gap="4px"
      className={styles.backNav}
    >
      <ArrowBack className={styles.backNavIcon} />
      {!isMobile && (
        <Text fontWeight={600} color="gray" fontSize={12} letterSpacing="1px">
          BACK
        </Text>
      )}
    </Box>
  );

  return (
    <BrandSetupWrapper
      pageTitle="My Brand Setup"
      wrapperClassName={styles.brandSetupReview}
      leftAction={
        isMobile &&
        shop.custom_product_status !== CUSTOM_PRODUCT_STATUS_IN_PROGRESS &&
        renderBackComponent()
      }
    >
      {!isMobile &&
        shop.custom_product_status !== CUSTOM_PRODUCT_STATUS_IN_PROGRESS &&
        renderBackComponent()}

      <Box className={styles.brandSetupReviewHeader}>
        <Box>
          <Box display="flex" alignItems="center">
            <Text variant="h1" fontSize="24" color="gray" mr={1}>
              Preview & approve your brand
            </Text>

            <Popper
              topOffSet={104}
              longText
              text="This is your chance to customize the size, rotation, and type
                  of your logos. Once those changes are made and you lock in
                  your logo, you cannot change them without restarting the brand
                  setup process (for a fee of $149)."
            >
              <InfoOutlinedIcon
                className={styles.brandSetupReviewHeaderIcon}
                color="#5E5E5E"
              />
            </Popper>
          </Box>
          <Text variant="body1" fontSize="14" mt={1}>
            You are now previewing how your brand will be printed on products.
            To adjust your logo on certain products, click the pencil icon{" "}
            <WandIcon size={10} color="#5E5F5F" /> and edit each individually.
            Once you&apos;re happy with how all of your products look, click
            &apos;approve&apos;.{" "}
            <i>
              Note: you will not be able to make any further edits or
              adjustments once approved!
            </i>
          </Text>
        </Box>

        <BrandSetupSteps />
      </Box>

      {shop.custom_product_status === CUSTOM_PRODUCT_STATUS_IN_PROGRESS && (
        <Box className={styles.brandSetupLoading}>
          <img
            className={styles.brandSetupLoadingGif}
            src={LoadingGif}
            alt="loading"
          />
          <Text
            variant="body1"
            fontSize={12}
            textTransform="uppercase"
            color="gray"
            mt={1}
            letterSpacing="2px"
            fontWeight={600}
            mb={4}
          >
            {generatorMessages[currentMessageIndex]}
          </Text>
        </Box>
      )}

      {shop.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE && (
        <>
          <Box className={styles.brandSetupReviewContent}>{renderGroups()}</Box>
          {/* {productGroups && ( */}
          <Box
            id="confirmBox"
            ref={confirmBoxRef}
            className={styles.brandSetupReviewCta}
          >
            <Text color="white" fontSize={21} variant="h2">
              Ready to launch your brand?
            </Text>
            <Button
              onClick={onNext}
              color="primary"
              variant="contained"
              hasChevron
            >
              Approve your logo
            </Button>
          </Box>
          {/* )} */}
          <Box className={styles.brandSetupReviewNote}>
            <Text color="gray" fontSize={12} variant="body1">
              Once you lock in your logo, you can’t make changes to your branded
              product designs.{` `}
              <a
                target="_blank"
                href="https://faq.blankabrand.com/en/articles/8823695-how-do-i-update-my-brand-plate"
                rel="noreferrer"
              >
                Learn more.
              </a>
            </Text>
          </Box>

          <Box
            id="confirmFooter"
            className={cn(
              styles.brandSetupReviewFooter,
              isConfirmBoxInView ? styles.hidden : styles.visible
            )}
          >
            <Box className={styles.footerContent}>
              <h3 className={styles.footerTitle}>Finished Reviewing?</h3>
              <Button
                onClick={onScrollBottom}
                className={styles.footerCta}
                color="primary"
                size="sm"
              >
                Approve
              </Button>
            </Box>
          </Box>
        </>
      )}

      <ModalWrapper
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        data-testid="confirmModal"
        className={styles.confirmModal}
      >
        <Box
          display="flex"
          flexDirection="column"
          rowGap={2}
          py={2}
          px={{ xs: 2, md: 4 }}
          textAlign="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap={2}
          >
            <ExclamationPointIcon className={styles.confirmIcon} />
            <Text className={styles.confirmTitle}>
              Have you carefully reviewed your mockups?
            </Text>
            <Text className={styles.confirmDescription}>
              Once you click &quot;YES, BUILD MY PRODUCT LINE&quot;, we will
              print your custom branded products{" "}
              <strong>as they appear in the previews</strong>. If you wish to
              make any further changes, updates or adjustments to your brand at
              a later date, this will require you to set up a whole new brand
              and incurs a fee of $149.
            </Text>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => onApproveBrandSetup(false)}
              loading={isLoading}
              disabled={isLoading}
              size={isMobile ? "small" : "medium"}
            >
              Yes, Build My Product Line
            </Button>

            <Text
              className={styles.confirmCancel}
              onClick={() => {
                setIsOpen(false);
                navigate(ROUTE_PATHS.HOME);
              }}
            >
              Save for later
            </Text>
          </Box>
        </Box>
      </ModalWrapper>

      {/* check with doug if this feature is applicable to free users, this is due to the fact 
      that the user is not able to make payment and i'll need to add the <UpdatePaymentStripe /> */}
      <Elements stripe={stripePromise}>
        <CheckoutConfirmPayment
          nextActionType={nextActionType}
          clientSecret={clientSecret}
          handlePaymentSuccess={handleSuccess}
          handlePaymentError={() => {
            setNextActionType();
            setClientSecret();
            setIsLoading(false);
          }}
        />
      </Elements>

      <ModalWrapper
        handleClose={() => {
          setSetupCardModal(false);
        }}
        isOpen={setupCardModal}
        data-testid="SetupCardModal"
        className={`${styles.stripeModalWrapper}`}
      >
        {!clientSecretCard && (
          <div className={styles.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        <UpdatePaymentStripe
          clientSecret={clientSecretCard}
          onError={onErrorUpdateCard}
          onSuccess={onSuccessUpdateCard}
          onCancel={() => setSetupCardModal(false)}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={blocker.state === "blocked"}
        handleClose={() => blocker.reset?.()}
        data-testid="blockerModal"
        className={styles.blockerModal}
      >
        <Box className={styles.blockerContent}>
          <WandIcon size={40} color="#D56F5B" />
          <h2>Hold up! You haven&apos;t approved your logo</h2>
          <p>
            Before we can print your personalized products, we need you to
            review and approve how your logo appears on each product.
          </p>

          <Button
            className={styles.blockerCta}
            color="primary"
            type="button"
            size="small"
            onClick={() => blocker.reset?.()}
          >
            KEEP REVIEWING
          </Button>

          <Text
            className={styles.blockerSaveForLater}
            onClick={() => blocker.proceed?.()}
          >
            Save for later
          </Text>
        </Box>
      </ModalWrapper>
    </BrandSetupWrapper>
  );
}

export default BrandSetupReviewView;
