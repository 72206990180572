import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowUpwardOutlined } from "@mui/icons-material";

import Text from "components/Text";
import Button from "components/Buttons/Button";

import { CART_TYPE_SAMPLE, CART_TYPE_INVENTORY } from "constants/constants";

// import CartTotals from "../CartTotals";
import CartTotals from "components/CartTotals/CartTotals";
import CartItem from "../CartItem";
import CheckoutFooter from "../../../CheckoutView/components/CheckoutFooter";

import styles from "./CartDesktopView.module.scss";

function CartDesktopView(props) {
  const {
    cart,
    onToggleSelectAll,
    selectAll,
    handleUpdate,
    handleRemoveItem,
    handleContinueToCheckout,
  } = props;

  return (
    <div className={`cart-template ${styles.cartDesktopView}`}>
      <Card variant="outlined" className={styles.card}>
        <TableContainer>
          <Table aria-label="">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Product</TableCell>
                <TableCell>Sku</TableCell>

                <TableCell>Quantity</TableCell>
                <TableCell align="right">Unit Cost (USD)</TableCell>
                <TableCell align="right">Total Cost (USD)</TableCell>
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.items.map((item) => (
                <CartItem
                  key={item.id}
                  item={item || []}
                  handleUpdate={handleUpdate}
                  handleRemoveItem={handleRemoveItem}
                  cartType={cart.type}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <div className={styles.footerContainer}>
        <CheckoutFooter />
      </div>

      <Box className={styles.adjustCartTotals}>
        <CartTotals cart={cart} step="cart" />
      </Box>

      <div className={styles.actionContainer}>
        <Button
          onClick={handleContinueToCheckout}
          variant="contained"
          color="primary"
          className="button button--primary"
          loading={cart.status === "loading"}
          disabled={cart.status === "idle" && cart.errors.length > 0}
        >
          Continue To Shipping{" "}
        </Button>
        <Text
          style={{ display: "block", marginTop: "5px" }}
          fontSize={10}
          className="text--upper"
          color="gray"
        >
          *shipping calculated at checkout
        </Text>
      </div>
    </div>
  );
}

CartDesktopView.propTypes = {
  cart: PropTypes.object.isRequired,
  onToggleSelectAll: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  handleContinueToCheckout: PropTypes.func.isRequired,
};

CartDesktopView.defaultProps = {};

export default CartDesktopView;
