import React, { useState } from "react";
import cn from "utils/cn";
import styles from "./Popper.module.scss";

function Popover({ children, text, topOffSet = 42, longText }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.popoverContainer}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>

      {anchorEl && (
        <div
          className={cn(
            styles.popoverContent,
            longText && styles.popoverContentBig
          )}
          style={{ top: anchorEl.offsetTop - topOffSet }}
        >
          <div className={styles.popoverArrow} />

          <div
            className={cn(
              styles.popoverBody,
              longText && styles.popoverBodyBig
            )}
          >
            <div className={styles.popoverArrowCover} />
            <span className={cn(!longText && styles.textOneLine)}>{text}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Popover;
