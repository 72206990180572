import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// MUI:
// import Button from "@mui/material/Button";
import { ArrowUpward } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { getShippingCost, getSamplesShippingCost } from "api/endpoints/cartApi";

// Components & constants:
import Button from "components/Buttons/Button";
import AccountAddressCard from "components/Cards/AccountAddressCard/AccountAddressCard";
import CartTotals from "components/CartTotals/CartTotals";
import AccountAddressesForm from "components/Forms/AccountAddressesForm";
import { CheckMarkIcon } from "components/Icons";
import ConfirmRemoveAddressModal from "components/Modals/ConfirmRemoveAddressModal/ConfirmRemoveAddressModal";
import PageHeader from "components/PageHeader";
import Text from "components/Text";
import {
  ACCOUNT_ADDRESSES_CREATED,
  ACCOUNT_ADDRESSES_UPDATED,
  MAX_ACCOUNT_ADDRESSES_TO_DISPLAY_FOR_CHECKOUT,
  SHIPPING_METHODS,
} from "constants/accountAddresses";
import { SMALL } from "constants/breakpoints";
import { CART_TYPE_INVENTORY, CART_TYPE_SAMPLE } from "constants/constants";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";

// hooks and services:
import useAccountAddresses from "hooks/useAccountAddresses";
import { useShippingMethod } from "hooks/useShippingMethod";

import { useCart } from "hooks";

import {
  getShippingAddressesAsync,
  selectStatus,
  setAddressToShip,
  setIsAdding,
  setIsEditing,
} from "state/accountAddressesSlice";

import MobileInput from "components/ui/MobileInput";
import { useForm } from "react-hook-form";
import cn from "utils/cn";

import { QUERY, updateUser } from "api";
import queryClient from "api/queryClient";
import { useQuery, useMutation } from "react-query";
import CartDiscountForm from "../CheckoutView/components/CheckoutDiscountForm";
import styles from "./CheckoutShippingView.module.scss";

function CheckoutShippingView() {
  const navigate = useNavigate();
  const cartHook = useCart();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const cartShippingState = useSelector((state) => state.cart.shipping);
  const profile = useSelector((state) => state.profile);

  const [storeAtBlanka, setStoreAtBlanka] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const withPhoneNumber = useMemo(
    () =>
      !!profile?.profile.phone_number && !!profile?.profile.phone_country_code,
    [profile]
  );

  // store & states for Addressess:
  const {
    addresses,
    addressToShip,
    maxAddresses,
    isEditing,
    isAdding,
    shippingMethod,
    handleOnClickEditAddress,
    handleOnRemoveAddress,
    handleOnSubmitForm,
    handleOnCancelForm,
  } = useAccountAddresses();

  const [open, setOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const status = useSelector(selectStatus);

  const {
    control,
    reset,
    handleSubmit: handlePhoneSubmit,
    watch,
  } = useForm({ defaultValues: { accepts_mobile_terms: false } });

  const { currentMethod, setShippingMethod, calculateTotalWeight } =
    useShippingMethod();
  const shippingAddress = useSelector((state) => state.cart.shipping);

  const { data: shippingCosts } = useQuery(
    [
      "shippingCosts",
      shippingAddress.country?.code,
      shippingAddress?.zip_code,
      cart.type,
      currentMethod?.type || "standard",
      calculateTotalWeight(),
    ],
    async () => {
      // If inventory and store at Blanka, return 0 shipping costs
      if (cart.type === CART_TYPE_INVENTORY && storeAtBlanka) {
        return {
          data: {
            standard_shipping_cost: { price: 0, transit_time: 0 },
            expedited_shipping_cost: { price: 0, transit_time: 0 },
          },
        };
      }

      if (cart.type === CART_TYPE_SAMPLE) {
        // Ensure shipping method is uppercase as default
        const shippingMethodToUse = (
          currentMethod?.type || "STANDARD"
        ).toUpperCase();
        // console.log("Using shipping method:", shippingMethodToUse);

        const response = await getSamplesShippingCost(
          shippingAddress.country?.code,
          shippingAddress.zip_code,
          (currentMethod?.type || "STANDARD").toUpperCase(),
          calculateTotalWeight()
        );
        return response;
      }

      // Original inventory shipping cost call
      const response = await getShippingCost(
        shippingAddress.country?.code,
        shippingAddress.zip_code,
        calculateTotalWeight()
      );
      return response;
    },
    {
      // Only enable if we have the required data and are not storing at Blanka
      enabled: !!(
        shippingAddress.country?.code &&
        shippingAddress.zip_code &&
        !(cart.type === CART_TYPE_INVENTORY && storeAtBlanka)
      ),
      select: (response) => ({
        standard: {
          price: response.data.standard_shipping_cost.price,
          transit_time: response.data.standard_shipping_cost.transit_time,
        },
        expedited: {
          price: response.data.expedited_shipping_cost.price,
          transit_time: response.data.expedited_shipping_cost.transit_time,
        },
      }),
    }
  );

  const handleShippingMethodChange = async (methodType) => {
    // Don't handle shipping method changes if storing at Blanka
    if (cart.type === CART_TYPE_INVENTORY && storeAtBlanka) {
      return;
    }

    try {
      const upperMethodType = methodType.toUpperCase();
      await setShippingMethod(methodType);

      const selectedCost =
        methodType === SHIPPING_METHODS.standard
          ? shippingCosts.standard.price
          : shippingCosts.expedited.price;

      await cartHook.updateShipping({
        ...shippingAddress,
        shipping_method: upperMethodType,
      });
    } catch (error) {
      console.error("Error setting shipping method:", error);
      toast.error("Failed to update shipping method. Please try again.");
    }
  };

  function syncAddressesAndShipping() {
    if (addresses.length === 0) {
      cartHook.updateShipping({});
      return;
    }

    if (addresses.length > 0) {
      if (cart.type === CART_TYPE_INVENTORY && storeAtBlanka) {
        cartHook.updateShipping({});
        return;
      }

      if (addressToShip && Object.keys(addressToShip).length === 0) {
        const defaultAddress = addresses.find((address) => address.default);

        dispatch(setAddressToShip(defaultAddress));

        cartHook.updateShipping({
          ...defaultAddress,
        });
        return;
      }

      dispatch(setAddressToShip(addressToShip));

      cartHook.updateShipping({
        ...addressToShip,
      });
    }
  }

  const updateUserMutation = useMutation(updateUser);

  // initial state for shipping in SAMPLES or INVENTORY orders:
  useEffect(() => {
    dispatch(getShippingAddressesAsync());
    dispatch(setIsEditing(false));
    if (addresses.length === 0) {
      dispatch(setIsAdding(true));
    } else {
      dispatch(setIsAdding(false));
    }
  }, []);

  useEffect(() => {
    if (addresses.length === 0) {
      dispatch(setIsAdding(true));
    } else {
      dispatch(setIsAdding(false));
    }
  }, [addresses.length]);

  // handle the shipping case:
  useEffect(() => {
    if (status === "idle") {
      syncAddressesAndShipping();
    }
  }, [status]);

  useEffect(() => {
    if (
      status === ACCOUNT_ADDRESSES_CREATED ||
      status === ACCOUNT_ADDRESSES_UPDATED
    ) {
      dispatch(getShippingAddressesAsync());
      // toast.success("Address has been saved!");
    }
  }, [status]);

  const handleOpenConfirmRemoveAddressModal = (id) => {
    setSelectedAddressId(id);
    setOpen(true);
  };

  const handleEditAddress = (id) => {
    handleOnClickEditAddress(id);
  };

  const handleOnClickRemoveAddress = () => {
    handleOnRemoveAddress(selectedAddressId);
    if (addressToShip.id === selectedAddressId) {
      dispatch(setAddressToShip({}));
    }
    setOpen(false);
  };

  const onSubmit = (e, data) => {
    const payload = {
      ...e,
      phone: `${e?.phone_country_code}${e?.phone_number}`,
    };

    if (!withPhoneNumber) {
      updateUserMutation.mutate(
        {
          phone_country_code: e?.phone_country_code,
          phone_number: e?.phone_number,
          accepts_mobile_terms: e?.accepts_mobile_terms,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(QUERY.getUser);
          },
        }
      );
    }

    handleOnSubmitForm(payload, data);
  };

  const handleUseNewAddress = () => {
    dispatch(setIsEditing(false));
    dispatch(setIsAdding(true));
  };

  const onCancel = () => {
    handleOnCancelForm();
  };

  const onSelectAddressToShip = (address) => {
    dispatch(setAddressToShip(address));
    dispatch(getShippingAddressesAsync());
  };

  const renderAddresses = () => {
    const firstFiveAddresses = addresses.slice(0, 5);
    const restOfAddresses = addresses.slice(5);

    return (
      <>
        {firstFiveAddresses.map((address) => (
          <Box key={address.id} className={styles.addressContainer}>
            <Box
              className={`${
                address.id === addressToShip?.id ? styles.tagActive : ""
              }`}
              onClick={() => {
                if (address.id !== addressToShip?.id) {
                  onSelectAddressToShip(address);
                }
              }}
            >
              {address.id === addressToShip?.id && (
                <CheckMarkIcon className={styles.tagDefaultIcon} />
              )}
              <AccountAddressCard
                key={address.id}
                address={address}
                handleOnClickEditAddress={handleEditAddress}
                handleOpenConfirmRemoveAddressModal={
                  handleOpenConfirmRemoveAddressModal
                }
              />
            </Box>
          </Box>
        ))}
        <Collapse in={showMore}>
          {restOfAddresses.map((address) => (
            <Box key={address.id} className={styles.addressContainer}>
              <Box
                className={`${
                  address.id === addressToShip?.id ? styles.tagActive : ""
                }`}
                onClick={() => onSelectAddressToShip(address)}
              >
                {address.id === addressToShip?.id && (
                  <CheckMarkIcon className={styles.tagDefaultIcon} />
                )}
                <AccountAddressCard
                  key={address.id}
                  address={address}
                  handleOnClickEditAddress={handleEditAddress}
                  handleOpenConfirmRemoveAddressModal={
                    handleOpenConfirmRemoveAddressModal
                  }
                />
              </Box>
            </Box>
          ))}
        </Collapse>
        <FormControl className={styles.shippingMethodFormControl}>
          <FormLabel className={styles.formLabel}>SHIPPING METHOD</FormLabel>
          <RadioGroup
            col
            aria-labelledby="row-radio-buttons-group-shipping-method"
            name="row-radio-buttons-group-shipping-method"
            className={styles.radioGroup}
            onChange={(e) => handleShippingMethodChange(e.target.value)}
            value={currentMethod?.type || ""}
          >
            <FormControlLabel
              value={SHIPPING_METHODS.standard}
              control={<Radio className={styles.radio} />}
              className={styles.labelWrapper}
              label={
                <div className={styles.radioWrapper}>
                  <Box className={styles.radioBox}>
                    <p className={styles.radioText}>
                      <span className={styles.bold}>Standard</span>
                      {shippingCosts?.standard?.price &&
                        shippingCosts.standard.transit_time && (
                          <>
                            {"  "}
                            (1 - {shippingCosts.standard.transit_time + 2} days)
                          </>
                        )}
                    </p>
                  </Box>
                  {shippingCosts?.standard?.price && (
                    <p className={`${styles.radioText} ${styles.priceText}`}>
                      ${Number(shippingCosts.standard.price).toFixed(2)}
                    </p>
                  )}
                </div>
              }
            />
            <FormControlLabel
              value={SHIPPING_METHODS.expedited}
              control={<Radio className={styles.radio} />}
              className={styles.labelWrapper}
              label={
                <div className={styles.radioWrapper}>
                  <Box className={styles.radioBox}>
                    <p className={styles.radioText}>
                      <span className={styles.bold}>Expedited</span>
                      {shippingCosts?.expedited?.price &&
                        shippingCosts.expedited.transit_time && (
                          <>
                            {" "}
                            (1 - {shippingCosts.expedited.transit_time + 2}{" "}
                            days)
                          </>
                        )}
                    </p>
                  </Box>
                  {shippingCosts?.expedited?.price && (
                    <p className={`${styles.radioText} ${styles.priceText}`}>
                      ${Number(shippingCosts.expedited.price).toFixed(2)}
                    </p>
                  )}
                </div>
              }
            />
          </RadioGroup>
          <Text fontSize="10">
            *Does not include{" "}
            <a
              href="https://faq.blankabrand.com/en/articles/8678128-how-long-does-it-take-for-my-customers-to-get-an-order?q=fulfilment+time"
              target="_blank"
              rel="noreferrer"
            >
              production and/or fulfilment time
            </a>{" "}
          </Text>
        </FormControl>
      </>
    );
  };

  const onSubmitPhone = async (data) => {
    updateUserMutation.mutate(data, {
      onSuccess: () => {
        queryClient.refetchQueries(QUERY.getUser);
        navigate("/checkout/payment");
      },
    });
  };

  const handleButtonClick = () => {
    handlePhoneSubmit(onSubmitPhone)();
  };

  const handleSubmit = async () => {
    try {
      // First ensure shipping method is set
      if (!currentMethod?.type && !storeAtBlanka) {
        toast.error("Please select a shipping method");
        return;
      }

      // Update cart with final shipping details
      await cartHook.updateShipping({
        ...shippingAddress,
        shipping_method: storeAtBlanka
          ? null
          : currentMethod?.type.toUpperCase(),
        shipping_cost: storeAtBlanka ? 0 : currentMethod?.price || 0,
      });

      // Check if using Blanka warehouse for inventory
      const usingBlankaWarehouse = storeAtBlanka;

      // If using Blanka warehouse or has phone number, proceed to payment
      if (usingBlankaWarehouse || withPhoneNumber) {
        navigate("/checkout/payment");
      } else {
        // Only handle phone submission if not using Blanka warehouse
        // and doesn't have phone number
        handleButtonClick();
      }
    } catch (error) {
      console.error("Error updating shipping:", error);
      toast.error("Failed to update shipping method. Please try again.");
    }
  };

  const shippingValid = () => {
    if (storeAtBlanka) {
      return true;
    }

    if (
      !cartShippingState.first_name ||
      !cartShippingState.last_name ||
      !cartShippingState.address_one ||
      !cartShippingState.city ||
      !cartShippingState.country ||
      !cartShippingState.zip_code ||
      !cartShippingState.phone
      // !cart.shipping.shipping_cost
    ) {
      return false;
    }

    return true;
  };

  return (
    <div
      className={`samples-checkout template-inventory-checkout__shipping checkout-shipping ${styles.cartShipping}`}
    >
      {!isMobile && <PageHeader title="Checkout" />}
      {isMobile && (
        <div className="checkout__breadcrumbs mb-30">
          <CheckoutFooter backLink="/cart" activeStep={1} />
          {isMobile && (
            <Divider orientation="horizontal" className={styles.divider} />
          )}
        </div>
      )}

      <Card className={styles.card} variant="outlined">
        <div className="shipping-card-left">
          {cart.type !== CART_TYPE_SAMPLE && (
            <Text
              variant="body1"
              color="peach"
              className="text--left text--bold text--nunito text--fs-15 mb-20 "
            >
              WHERE DO YOU WANT YOUR INVENTORY STORED?
            </Text>
          )}

          {cart.type !== CART_TYPE_SAMPLE && (
            <FormGroup>
              <FormControlLabel
                onChange={async (e) => {
                  if (e.target.checked) {
                    setStoreAtBlanka(true);
                    dispatch(getShippingAddressesAsync());
                  }
                }}
                disabled={cart.type === CART_TYPE_SAMPLE}
                checked={storeAtBlanka}
                className={styles.label}
                control={<Checkbox />}
                label="STORE MY INVENTORY AT THE BLANKA WAREHOUSE"
              />

              <FormControlLabel
                onChange={(e) => {
                  if (e.target.checked) {
                    setStoreAtBlanka(false);
                  }
                  dispatch(getShippingAddressesAsync());
                }}
                checked={!storeAtBlanka}
                control={<Checkbox />}
                className={styles.label}
                label="SHIP MY INVENTORY TO ME "
              />
            </FormGroup>
          )}

          <Collapse in={!storeAtBlanka}>
            <div className={styles.shippingHeader}>
              <Text variant="h1" className={styles.headingTitle}>
                Shipping Information
              </Text>
              <Box className={styles.boxContainer}>
                {!isAdding &&
                  !isEditing &&
                  addresses.length !== 0 &&
                  status === "idle" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={handleUseNewAddress}
                      disabled={addresses.length >= maxAddresses}
                    >
                      Use new Address
                    </Button>
                  )}
              </Box>
            </div>
            {(isAdding || isEditing) && status === "idle" && (
              <Box>
                <Text
                  variant="h4"
                  className={
                    !isMobile
                      ? styles.subtitle
                      : "text--left text--gray text--georgia-bold text--fs-24 mt-15"
                  }
                >
                  {isAdding ? "Save" : "Edit"} Address
                </Text>
                <AccountAddressesForm
                  onSubmit={onSubmit}
                  onCancel={onCancel}
                  withPhoneNumber={withPhoneNumber}
                />
              </Box>
            )}
            {!(isAdding || isEditing) && status === "idle" && renderAddresses()}
            {addresses.length > MAX_ACCOUNT_ADDRESSES_TO_DISPLAY_FOR_CHECKOUT &&
              !(isEditing || isAdding) && (
                <div
                  className={styles.showMoreBtn}
                  onClick={() => setShowMore(!showMore)}
                >
                  <span className={styles.label}>
                    {showMore ? "Show Less" : "Show More "}
                    {showMore ? <ArrowUpward /> : <ArrowDownwardIcon />}
                  </span>
                </div>
              )}
          </Collapse>
        </div>

        {!isMobile && (
          <div className={`shipping-card-right ${styles.limitCartTotals}`}>
            <Stack className={styles.stackContainer}>
              {cart.type === CART_TYPE_SAMPLE && (
                <CartDiscountForm disabled={false} />
              )}
              <CartTotals cart={cart} step="shipping" />
            </Stack>
          </div>
        )}
      </Card>

      {isMobile && (
        <div className="shipping-card-right">
          <Stack className={styles.stackContainer}>
            {cart.type === CART_TYPE_SAMPLE && (
              <CartDiscountForm disabled={false} />
            )}
            <CartTotals cart={cart} step="shipping" />
          </Stack>
        </div>
      )}

      {!withPhoneNumber && !isAdding && !isEditing && (
        <Card
          className={cn(styles.card, styles.phoneNumberCard)}
          variant="outlined"
        >
          <Text variant="h2" className={styles.headingTitle}>
            Stay up to date
          </Text>
          <form>
            <MobileInput
              control={control}
              reset={reset}
              isEditMode
              className={styles.phoneNumberInput}
              checkboxClassName={styles.phoneNumberCheckbox}
            />
          </form>
        </Card>
      )}

      {isMobile && (
        <div className="mt-10">
          <Button
            disabled={
              !shippingValid() ||
              cartHook.cart.status === "loading" ||
              status === "loading"
            }
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className="button button--primary"
            fullWidth={isMobile}
          >
            Continue To Payment
          </Button>
        </div>
      )}

      {!isMobile && (
        <div className="checkout__actions">
          <div className="checkout__breadcrumbs">
            <CheckoutFooter backLink="/cart" activeStep={1} />
          </div>

          <div className="checkout-button-container">
            <Button
              disabled={
                !shippingValid() ||
                cartHook.cart.status === "loading" ||
                status === "loading" ||
                (!storeAtBlanka && !currentMethod?.type)
              }
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="button button--primary"
              loading={
                cartHook.cart.status === "loading" || status === "loading"
              }
            >
              Continue To Payment
            </Button>
          </div>
        </div>
      )}

      <ConfirmRemoveAddressModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={handleOnClickRemoveAddress}
        address={addresses?.find((address) => address.id === selectedAddressId)}
      />
    </div>
  );
}

export default CheckoutShippingView;
