import TextField from "@mui/material/TextField";
import Button from "components/Buttons/Button";
import PasswordStrengthBar from "components/PasswordStrengthBar";
import Text from "components/Text";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Checkbox } from "@mui/material";

import Divider from "@mui/material/Divider";
import {
  appleSSOLogin,
  createUser,
  googleSSOLogin,
  login,
  updateUser,
} from "api";
import { verifyRecaptcha } from "api/endpoints/shopApi";
import AppleLoginButton from "components/Buttons/AppleLoginButton";
import GoogleLoginButton from "components/Buttons/GoogleLoginButton";
import { TEST_ONBOARDING_COOKIE_NAME } from "constants/ab_tests";
import { SMALL } from "constants/breakpoints";
import { useAnalytics, useConversionTracking } from "hooks";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";

import MobileInput from "components/ui/MobileInput";
import { setToken } from "state/sessionSlice";
import styles from "./SignUpForm.module.scss";

const API_KEY = "AIzaSyD3FfW4MjqS4civBa2O6gpHNcTkq0QOusI";
const secretKey = "6LcAy2kqAAAAAL_U7SZf1yzprSyuRW7F3YA5IF1z";

export default function SignUpForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const conversionTracking = useConversionTracking();
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const navigate = useNavigate();

  const [message, setMessage] = useState();
  const analytics = useAnalytics();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [loading, setLoading] = useState(false);
  const mutation = useMutation(createUser);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid },
    setError,
  } = useForm({ mode: "onChange" });
  const watchPassword = watch("password");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const getSignupSource = () => {
    let signupSource = "organic";
    if (sessionStorage.getItem("woocommerce_store")) {
      signupSource = "woocomerce";
    } else if (sessionStorage.getItem("wix_store")) {
      signupSource = "wix";
    } else if (sessionStorage.getItem("shopify_store")) {
      signupSource = "shopify";
    }

    return signupSource;
  };

  const sendEvent = () => {
    analytics.sendEvent(analytics.USER_REGISTRATION, {
      source: getSignupSource(),
    });
    conversionTracking.signup(0);
  };
  const verifyRecaptchaMutation = useMutation({
    mutationFn: (token) => verifyRecaptcha(token),
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const onSubmit = async (data) => {
    setMessage("");
    const signupVersion = Cookies.get(TEST_ONBOARDING_COOKIE_NAME);

    mutation.mutate(
      {
        ...data,
        signup_source: getSignupSource(),
        signup_version: signupVersion,
      },
      {
        onSuccess: () => {
          sendEvent();
          login({ username: data.email, password: data.password }).then(
            (res) => {
              dispatch(setToken(res.data));
              setLoading(false);
            }
          );
        },
        onError: (err) => {
          setLoading(false);
          if (err.response.data?.error_code === "ERROR_PASSWORD_STRENGTH") {
            setMessage(err.response.data.message);
            return;
          }

          if (err?.response?.data?.email) {
            setError("email", {
              type: "manual",
              message: err?.response?.data?.email?.[0] ?? "Email is invalid.",
            });
          }

          setMessage(
            err.response.data.message
              ? err.response.data.message
              : "There was an error creating your account"
          );
        },
      }
    );
  };

  const handleReCaptchaVerify = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      setLoading(true);
      const recaptchaToken = await executeRecaptcha("SIGNUP");
      if (recaptchaToken) {
        onSubmit({ ...data, recaptcha: recaptchaToken });
      }
    },
    [executeRecaptcha]
  );

  const handleGoogleLogin = async (googleData) => {
    try {
      setLoading(true);
      const response = await googleSSOLogin(googleData.access_token);

      sendEvent();
      await dispatch(
        setToken({
          access: response.data.access,
          refresh: response.data.refresh,
        })
      );

      updateUser({ signup_version: Cookies.get(TEST_ONBOARDING_COOKIE_NAME) });
      setLoading(false);
    } catch (err) {
      setLoading(false);

      try {
        setMessage(
          `Oops! Could not login with your Google account. ${
            err.response?.data?.non_field_errors[0]
              ? err.response?.data?.non_field_errors[0]
              : ""
          }`
        );
      } catch (e) {
        setMessage(`Oops! Could not login with your Google account.`);
      }
    }
  };

  const handleAppleLogin = async (code) => {
    try {
      setLoading(true);
      const response = await appleSSOLogin(code);
      sendEvent();
      await dispatch(
        setToken({
          access: response.data.access,
          refresh: response.data.refresh,
        })
      );

      updateUser({ signup_version: Cookies.get(TEST_ONBOARDING_COOKIE_NAME) });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMessage(
        `Oops! Could not login with your Apple account. ${
          err.response?.data?.non_field_errors[0]
            ? err.response?.data.non_field_errors[0]
            : ""
        }`
      );
    }
  };

  return (
    <div className={styles.signUpFormContainer}>
      <form
        className={styles.form}
        onSubmit={handleSubmit(handleReCaptchaVerify)}
      >
        <div />

        <div className={styles.ssoContainer}>
          <GoogleLoginButton handleLogin={handleGoogleLogin} />
          <AppleLoginButton handleLogin={handleAppleLogin} />
        </div>

        <Divider className={styles.divider} variant="middle">
          OR
        </Divider>

        {!isMobile && (
          <div className={styles.fullNameContainer}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First Name"
                  className={styles.fieldWidth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              rules={{ required: true }}
            />

            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Last Name"
                  className={styles.fieldWidth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              rules={{ required: true }}
            />
          </div>
        )}

        {isMobile && (
          <div className={styles.fullNameContainer}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={styles.fieldWidth}
                />
              )}
              rules={{ required: true }}
            />

            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Last Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={styles.fieldWidth}
                />
              )}
              rules={{ required: true }}
            />
          </div>
        )}
        <div className={styles.emailPassContainer}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/ }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error ? error.message : ""}
                className={styles.textFieldWidth}
                label="Email"
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>

        <div className={styles.emailPassContainer}>
          <MobileInput
            control={control}
            name="phone_number"
            label="Phone Number"
            hideAcceptTerms
            className={styles.mobileInputForm}
          />
        </div>

        <div className={styles.emailPassContainer}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                className={styles.textFieldWidth}
                type="password"
              />
            )}
            rules={{ required: true }}
          />
          <PasswordStrengthBar
            password={watchPassword}
            width="300px"
            margin="10px 0 0 0"
            handleStrengthChange={(strength) => setPasswordStrength(strength)}
            className={styles.passwordStrengthBar}
          />

          <Controller
            name="accepts_mobile_terms"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <div className={styles.mobileTermsWrapper}>
                <Checkbox {...field} checked={field.value} />
                <p>
                  I agree to Blanka keeping me informed with news, offers,
                  products, and promotions at the phone number provided. View
                  our{" "}
                  <a
                    href="https://blankabrand.com/pages/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://blankabrand.com/pages/blanka-terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                  . You may opt-out anytime. Msg and data rates may apply.
                </p>
              </div>
            )}
          />

          <div className={styles.termsConditionsContainer}>
            <p className={styles.termsText}>
              By signing up you agree to Blanka&apos;s{" "}
              <a
                target="__blank"
                href="https://blankabrand.com/blanka-terms-and-conditions/"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                target="__blank"
                href="https://faq.blankabrand.com/en/articles/5924132-how-does-blanka-handle-subscription-refunds"
              >
                Refund &amp; Cancellation Policy.
              </a>
            </p>
          </div>

          <div className={styles.recaptchaContainer}>
            <p className={styles.recaptchaText}>
              This site is protected by reCAPTCHA and the Google{" "}
              <a target="__blank" href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a target="__blank" href="https://policies.google.com/terms">
                Terms of Service
              </a>{" "}
              apply.
            </p>
          </div>
        </div>

        <div className={styles.actionContainer}>
          <Button
            loading={loading}
            disabled={
              !isDirty ||
              !isValid ||
              watchPassword.length < 8 ||
              passwordStrength < 3
            }
            color="secondary"
            type="submit"
            variant="contained"
            size="large"
          >
            SIGN UP
          </Button>

          <Text
            fontSize={10}
            className={`text--nunito text--error ${styles.textError}`}
          >
            {" "}
            {message}
          </Text>
        </div>
      </form>
    </div>
  );
}
