const ROUTE_PATHS = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  PRODUCTS_SEARCH: "/products/search",
  PRODUCTS_DETAILS: "/products/:sku",
  BRAND_PLATE: "/brand-plate",
  BRAND_PLATE_SETUP: "/brand-plate/setup",
  BRAND_PLATE_PAST_ORDERS: "/brand-plate/past-orders",
  MY_PRODUCTS: "/my-products",
  BLANKA_FORMULATOR: "/blanka-formulator",
  SAMPLE_ORDERS: "/sample-orders",
  CUSTOMER_ORDERS: "/orders",
  INVENTORY_ORDERS: "/inventory-orders",
  PROFILE: "/profile",
  PROFILE_ADDRESSES: "/profile/addresses",
  PROFILE_ADDRESSES_UPDATE: "/profile/addresses/update",
  PROFILE_ADDRESSES_NEW: "/profile/addresses/new",
  PROFILE_BILLING: "/profile/billing",
  PROFILE_BILLING_UPDATE: "/profile/billing/update",
  PROFILE_PASSWORD: "/profile/password",
  PROFILE_PASSWORD_UPDATE: "/profile/password/update",
  PROFILE_STORE_CONNECT: "/profile/connect",
  PROFILE_INFORMATION: "/profile/information",
  PROFILE_SETTINGS: "/profile/settings",
  CART: "/cart",
  CHECKOUT_SHIPPING: "/checkout/shipping",
  CHECKOUT_PAYMENT: "/checkout/payment",
  CHECKOUT_COMPLETE: "/checkout/thank-you",
  BRAND_SETUP: "/brand-setup",
  BRAND_SETUP_UPLOAD: "/brand-setup/upload",
  BRAND_SETUP_MOCKUPS_CHECKOUT: "/brand-setup/mockups-checkout",
  BRAND_SETUP_CUSTOMIZE: "/brand-setup/customize/:sku",
  BRAND_SETUP_REVIEW: "/brand-setup/review",
  BRAND_SETUP_SUCCESS: "/brand-setup/success",
  SEARCH: "/search",
  MY_LOGO: "my-logo",
  LOGIN: "/login",
  SHOPIFY_LOGIN: "/login/shopify",
  WIX_LOGIN: "/login/wix",
  WOOCOMMERCE_LOGIN: "/login/woocommerce",
  SIGNUP: "/signup",
  PASSWORD_RESET: "/password-reset",
  PASSWORD_SET: "/password-set",
  LOGOUT: "/logout",
  DOWNGRADE_FEEDBACK: "/downgrade/feedback",
  DOWNGRADE_REVIEW: "/downgrade/review",
  DOWNGRADE_CANCEL_SUBSCRIPTION: "/downgrade/cancel-subscription",
  FAVORITES: "/favorites",
};

export default ROUTE_PATHS;
