import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAnalytics from "./useAnalytics";

export function usePageViews(user) {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    // set as user
    try {
      // return if not in production
      if (process.env.NODE_ENV !== "production") {
        return;
      }

      if (user !== null && user.shop) {
        window.analytics.identify(user?.id, {
          email: user?.email,
          name: user?.name,
        });

        window.gtag("set", {
          user_id: `${user?.id}`,
        });

        window.amplitude.setUserId(user?.id);
      }
    } catch (err) {
      console.error(err);
    }

    // catch gtag for testing purposes
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_source: urlParams.get("utm_source"),
        utm_medium: urlParams.get("utm_medium"),
        utm_campaign: urlParams.get("utm_campaign"),
        utm_term: urlParams.get("utm_term"),
        utm_content: urlParams.get("utm_content"),
      };

      const referringDomain = document.referrer
        ? new URL(document.referrer).hostname
        : null;

      analytics.sendEvent(analytics.PAGE_VIEW, {
        platform: "app",
        page_title: document.title,
        page_location: location.pathname,
        url: window.location.href,
        referrer: document.referrer,
        referring_domain: referringDomain,
        ...utmParams,
      });

      window.gtag("event", "page_view", {
        page_title: location.pathname,
        page_location: location.pathname,
      });

      window.analytics.page(location.name, {
        title: location.name,
        path: location.pathname,
        url: window.location.href,
      });
    } catch (err) {
      console.log("Error loading gtag");
    }
  }, [location]);
}
