import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import styles from "./FormDropdown.module.scss";

function FormDropdown({
  control,
  name,
  options = [],
  defaultValue,
  rules = {},
  disabled = false,
  placeholder = "",
  onChange: externalOnChange,
  value: externalValue,
}) {
  return (
    <div className={styles.formDropdownContainer}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          useEffect(() => {
            if (externalValue && externalValue !== field.value) {
              field.onChange(externalValue);
            }
          }, [externalValue]);

          return (
            <CustomDropdown
              options={options}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                if (externalOnChange) {
                  externalOnChange(value);
                }
              }}
              disabled={disabled}
              placeholder={placeholder}
              error={error}
            />
          );
        }}
      />
    </div>
  );
}

function CustomDropdown({ options, value, onChange, disabled, error }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Handle clicking outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Get display value (either selected option or placeholder)
  const getDisplayValue = () => {
    // if (!value) return placeholder;

    const option = options.find((opt) =>
      typeof opt === "object" ? opt.value === value : opt === value
    );

    if (!option) {
      return value;
    }

    if (typeof option === "object") {
      return option.label;
    }

    return option;
  };

  const handleOptionClick = (option) => {
    const optionValue = typeof option === "object" ? option.value : option;
    onChange(optionValue);
    setIsOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={`${styles.dropdownWrapper} ${error ? styles.error : ""} ${disabled ? styles.disabled : ""}`}
    >
      <div
        className={styles.selectControl}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <span className={styles.selectedValue}>{getDisplayValue()}</span>
        <KeyboardArrowDownIcon
          className={`${styles.dropdownIcon} ${isOpen ? styles.rotate : ""}`}
          fontSize="small"
        />
      </div>

      {isOpen && !disabled && (
        <div className={styles.dropdownMenu}>
          {options.map((option, index) => {
            const optionValue =
              typeof option === "object" ? option.value : option;
            const optionLabel =
              typeof option === "object" ? option.label : option;

            return (
              <div
                key={typeof option === "object" ? option.value : option}
                className={`${styles.optionItem} ${value === optionValue ? styles.selected : ""}`}
                onClick={() => handleOptionClick(option)}
              >
                <span>{optionLabel}</span>
              </div>
            );
          })}
        </div>
      )}

      {error && <div className={styles.errorMessage}>{error.message}</div>}
    </div>
  );
}

export default FormDropdown;
