import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import styles from "./SuspenseLoading.module.scss";

function SuspenseLoading({ size = 30 }) {
  return (
    <div className={styles.container}>
      <CircularProgress size={size} />
    </div>
  );
}

export default SuspenseLoading;
