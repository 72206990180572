import React, { useMemo, useState } from "react";

import HeartIcon from "components/Icons/HeartIcon";
import cn from "utils/cn";
import darkenColor from "views/FindProductsView/components/ProductCard/darkenColor";
import styles from "../../ProductView.module.scss";

function VariantButton({ variant, onChangeVariant, selectedVariant }) {
  const [hovered, setHovered] = useState(false);

  const isSelected = useMemo(
    () => selectedVariant?.sku === variant.sku,
    [selectedVariant, variant?.sku]
  );

  const isFavorite = useMemo(
    () => variant?.categories?.includes("favorites"),
    [variant]
  );

  return (
    <button
      type="button"
      onClick={() => onChangeVariant(variant)}
      className={cn(
        styles.variantBlock,
        isSelected && styles.variantBlockActive
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {(hovered || isSelected) && isFavorite && (
        <div className={styles.variantFavorite}>
          <HeartIcon size={14} isActive />
        </div>
      )}
      {variant?.color_code ? (
        <div
          style={{
            border: `1px solid ${darkenColor(variant?.color_code, 12)}`,
            background: variant?.color_code,
          }}
          className={cn(
            styles.variantCircle,
            variant?.product_base?.out_of_stock &&
              styles.variantCircleOutOfStock
          )}
        >
          {variant?.added_to_my_products &&
            selectedVariant?.product_base.out_of_stock && (
              <div className={styles.variantSelected}>
                <img
                  style={{ height: 10, width: 10 }}
                  src="/icon/product-view/product-check.svg"
                  alt="check"
                />
              </div>
            )}
        </div>
      ) : (
        <img
          className={styles.variantCircle}
          src="/icon/product-view/no-color.svg"
          alt="no color"
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 24,
          justifyContent: "center",
        }}
      >
        <span
          style={
            selectedVariant?.sku === variant?.sku ? { color: "#D56F5B" } : {}
          }
          className={styles.variantName}
        >
          {variant?.color_name}
        </span>
        <span
          className={cn(styles.skuLabel, isSelected && styles.skuLabelActive)}
          style={{
            fontSize: "8px",
            letterSpacing: "2px",
            lineHeight: "10px",
            fontWeight: 600,
            textTransform: "uppercase",
            color: "#5E5E5E",
          }}
        >
          {variant?.sku}
        </span>
      </div>
    </button>
  );
}

export default VariantButton;
