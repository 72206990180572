import { InfoOutlined } from "@mui/icons-material";
import React, { useMemo } from "react";
import cn from "utils/cn";
import styles from "./Alert.module.scss";

function Alert({ text, variant, description }) {
  const variantStyle = useMemo(() => {
    switch (variant) {
      case "error":
        return styles.alertError;

      case "warning":
        return styles.alertWarning;

      case "success":
        return styles.alertSuccess;

      // make more variants
      default:
        return styles.alertError;
    }
  }, []);
  return (
    <div className={cn(styles.root, variantStyle)}>
      <InfoOutlined />
      <div className={styles.content}>
        <span className={styles.title}>{text}</span>
        {description && (
          <span className={styles.description}>{description}</span>
        )}
      </div>
    </div>
  );
}

export default Alert;
