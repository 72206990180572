import { parsePhoneNumberFromString } from "libphonenumber-js";

const removeCountryCode = (phoneNumber) => {
  if (phoneNumber) {
    const parsed = parsePhoneNumberFromString(phoneNumber);
    if (parsed) {
      return parsed.nationalNumber;
    }
  }
  return phoneNumber;
};

export default removeCountryCode;
