import { useDispatch, useSelector } from "react-redux";
import { updateShippingMethod, selectShippingMethod } from "state/cartSlice";
import { SHIPPING_METHODS } from "constants/accountAddresses";
import { getShippingCost, getSamplesShippingCost } from "api/endpoints/cartApi";
import { CART_TYPE_SAMPLE } from "constants/constants";
import { toast } from "react-toastify";

export const useShippingMethod = () => {
  const dispatch = useDispatch();
  const currentMethod = useSelector(selectShippingMethod);
  const shippingAddress = useSelector((state) => state.cart.shipping);
  const cartType = useSelector((state) => state.cart.type);
  const cart = useSelector((state) => state.cart);

  const calculateTotalWeight = () => {
    if (!cart.items || cart.items.length === 0) return 0;

    return cart.items.reduce((total, item) => {
      const itemWeight = item.product?.weight || 0;
      const quantity = item.quantity || 1;
      return total + itemWeight * quantity;
    }, 0);
  };

  const fetchShippingCosts = async (methodType = "STANDARD") => {
    try {
      const totalWeight = calculateTotalWeight();

      if (cartType === CART_TYPE_SAMPLE) {
        const response = await getSamplesShippingCost(
          shippingAddress.country?.code,
          shippingAddress.zip_code,
          methodType.toUpperCase(),
          totalWeight
        );
        return {
          standard: {
            price: response.data.standard_shipping_cost.price,
            transit_time: response.data.standard_shipping_cost.transit_time,
          },
          expedited: {
            price: response.data.expedited_shipping_cost.price,
            transit_time: response.data.expedited_shipping_cost.transit_time,
          },
        };
      }

      // Original inventory shipping cost call
      const response = await getShippingCost(
        shippingAddress.country?.code,
        shippingAddress.zip_code,
        totalWeight
      );

      return {
        standard: {
          price: response.data.standard_shipping_cost.price,
          transit_time: response.data.standard_shipping_cost.transit_time,
        },
        expedited: {
          price: response.data.expedited_shipping_cost.price,
          transit_time: response.data.expedited_shipping_cost.transit_time,
        },
      };
    } catch (error) {
      console.error("Failed to fetch shipping costs:", error);
      throw error;
    }
  };

  const setShippingMethod = async (methodType) => {
    try {
      const costs = await fetchShippingCosts(methodType);

      const methodData = {
        type: methodType,
        price:
          methodType === SHIPPING_METHODS.standard
            ? costs.standard.price
            : costs.expedited.price,
        transit_time:
          methodType === SHIPPING_METHODS.standard
            ? costs.standard.transit_time + 2
            : costs.expedited.transit_time + 2,
      };

      dispatch(updateShippingMethod(methodData));
    } catch (error) {
      console.error("Shipping method error:", error);
      toast.error("Failed to get shipping costs. Please try again.");
    }
  };

  return {
    currentMethod,
    setShippingMethod,
    calculateTotalWeight,
  };
};
