import React, { useState } from "react";

import { QUERY, updateUser } from "api";
import queryClient from "api/queryClient";
import { COUNTRIES } from "constants/countries";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { Autocomplete, Checkbox, TextField } from "@mui/material";
import Button from "components/Buttons/Button";
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";
import { Controller, useWatch } from "react-hook-form";

import cn from "utils/cn";
import styles from "./MobileInput.module.scss";

function MobileInput({
  control,
  rules,
  disabled,
  reset,
  isEditMode,
  countryDefault,
  className,
  checkboxClassName,
  hideAcceptTerms,
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(countryDefault || "+1");
  const [removeModal, setRemoveModal] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const updateUserMutation = useMutation(updateUser);

  const selectedCountryCode = useWatch({
    control,
    name: "phone_country_code",
  });

  const onRemovePhoneNumber = () => {
    updateUserMutation.mutate(
      { phone_country_code: "", phone_number: "", accepts_mobile_terms: false },
      {
        onSuccess: () => {
          queryClient.refetchQueries(QUERY.getUser).then(() => {
            reset({
              phone_country_code: "+1",
              phone_number: "",
              accepts_mobile_terms: false,
            });
            setInputValue("+1");
            toast.success("Success! Your account has been updated.");
            setRemoveModal(false);
          });
        },
      }
    );
  };

  return (
    <>
      <div className={cn(className)}>
        <div>
          <div className={styles.mobileInputWrapper}>
            {disabled && isEditMode && (
              <div className={styles.removeWrapper}>
                <span
                  onClick={() => setRemoveModal(true)}
                  className={styles.removeText}
                >
                  Remove
                </span>
              </div>
            )}
            <div className={styles.customInput}>
              <Text className={styles.label}>Country</Text>
              <Controller
                name="phone_country_code"
                control={control}
                defaultValue="+1"
                rules={rules}
                render={({ field, fieldState: { error } }) => {
                  const selectedOption = COUNTRIES.find(
                    (c) => c.country_code === field.value
                  );

                  return (
                    <Autocomplete
                      {...field}
                      disabled={disabled}
                      value={selectedOption || null}
                      onChange={(_, selectedOption) => {
                        const code = selectedOption
                          ? selectedOption.country_code
                          : "";
                        field.onChange(code);
                        setInputValue(code);
                      }}
                      inputValue={inputValue}
                      onInputChange={(_, newInputValue, reason) => {
                        if (reason !== "reset") {
                          setInputValue(newInputValue);
                        }
                      }}
                      disableClearable
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      componentsProps={{
                        paper: {
                          style: {
                            minWidth: "200px",
                          },
                        },
                      }}
                      classes={{
                        root: cn(invalidPhoneNumber && styles.errorInput),
                      }}
                      options={COUNTRIES}
                      getOptionLabel={(option) =>
                        option
                          ? `${option.name} (${option.code}) ${option.country_code}`
                          : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.country_code === value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          className={cn(
                            invalidPhoneNumber && styles.countryInputError
                          )}
                          classes={{
                            root: cn(isFocused && styles.countryInputFocused),
                          }}
                        />
                      )}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.customInput}>
              <Text className={styles.label}>Phone number</Text>
              <Controller
                name="phone_number"
                control={control}
                rules={{
                  ...rules,
                  validate: (value) => {
                    if (!value) {
                      return true;
                    }

                    const country = COUNTRIES.find(
                      (c) => c.country_code === selectedCountryCode
                    );
                    const countryCode = country?.code || "CA";

                    const phoneNumber = parsePhoneNumberFromString(
                      `${inputValue}${value}`,
                      countryCode
                    );

                    if (phoneNumber && phoneNumber.isValid()) {
                      setInvalidPhoneNumber(false);
                      return true;
                    }

                    setInvalidPhoneNumber(true);
                    return "";
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, "");
                      field.onChange(numericValue);
                    }}
                    aria-label="mobile-number"
                    variant="outlined"
                    disabled={disabled}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    fullWidth
                    classes={{
                      root: cn(invalidPhoneNumber && styles.errorInput),
                    }}
                  />
                )}
              />
            </div>
          </div>
          {invalidPhoneNumber && (
            <span className={styles.mobileInputErrorMessage}>
              Oops! Please check this is a valid SMS-capable phone number.
            </span>
          )}
        </div>

        {!hideAcceptTerms && (
          <Controller
            name="accepts_mobile_terms"
            control={control}
            render={({ field }) => (
              <div
                className={cn(
                  styles.acceptMobileTermsWrapper,
                  checkboxClassName
                )}
              >
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled={!isEditMode}
                />
                <p>
                  I agree to Blanka keeping me informed with news, offers,
                  products, and promotions at the phone number provided. View
                  our{" "}
                  <a
                    href="https://blankabrand.com/pages/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://blankabrand.com/pages/blanka-terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                  . You may opt-out anytime. Msg and data rates may apply.
                </p>
              </div>
            )}
          />
        )}
      </div>

      <ModalWrapper
        isOpen={removeModal}
        handleClose={() => setRemoveModal(false)}
        data-testid="RemovePHoneModal"
        className={styles.removePhoneModal}
      >
        <h2 className={styles.title}>
          Are you sure you want to remove your
          <br />
          phone number?
        </h2>
        <p className={styles.description}>
          You might miss out on something awesome!
        </p>
        <div className={styles.actions}>
          <Button
            onClick={onRemovePhoneNumber}
            size="small"
            type="submit"
            variant="contained"
            color="primary"
            disabled={updateUserMutation.isLoading}
            loading={updateUserMutation.isLoading}
          >
            Remove
          </Button>
          <Button
            onClick={() => setRemoveModal(false)}
            size="small"
            variant="outlined"
            color="primary"
            disabled={updateUserMutation.isLoading}
          >
            Nevermind
          </Button>
        </div>
      </ModalWrapper>
    </>
  );
}

export default MobileInput;
