import React, { useEffect, useState } from "react";

import { Box, FormControl, Select, MenuItem } from "@mui/material";

import AccountNav from "components/AccountNav";
import AccountCard from "components/Cards/AccountCard";
import PageHeader from "components/PageHeader/PageHeader";
import Text from "components/Text";

import { QUERY } from "api";
import { IOSSwitch } from "components/Switch/Switch";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import FormDropdown from "components/Input/FormDropdown";

import {
  getShopPreferences,
  getTransports,
  updateShopPreferences,
} from "api/endpoints/profileApi";

import { updateCustomerOrdersShippingMethod } from "api/endpoints/shopApi";

import { useProfile } from "hooks";

import { useDispatch, useSelector } from "react-redux";
import {
  selectShopSettings,
  setAutomaticPaymentsThunk,
  getShopSettingsThunk,
  setCustomerOrdersShippingMethodThunk,
} from "state/shopSettignsSlice";
import { updateShippingMethod, selectShippingMethod } from "state/cartSlice";
// import { updateShopAutomaticPayments } from "api/endpoints/shopApi";

import cn from "utils/cn";

import PaywallUpgradeModal from "components/Modals/PaywallUpgradeModal";
import useChangePlanModal from "hooks/useChangePlanModal";
import Dropdown from "components/Dropdown";
import styles from "./ProfileSettingsView.module.scss";

export default function ProfileSettingsView() {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      shippingMethod: "standard",
    },
  });

  const dispatch = useDispatch();
  const shopSettings = useSelector(selectShopSettings);
  const currentShippingMethod = useSelector(selectShippingMethod);
  const autoPaymentsConfigFlag = useSelector(
    (state) => state.profile.shop.config_flags.SHOP_AUTO_PAY_CUSTOMER_ORDERS
  );

  const shop = useSelector((state) => state.profile.shop);
  const profileHook = useProfile();
  const { onOpenModal } = useChangePlanModal();

  const [isEditingUnits, setIsEditingUnits] = useState(false);
  const [outOfStockUnits, setOutOfStockUnits] = useState(0);
  const [selectedTransports, setSelectedTransports] = useState([]);
  const [selectedShippingMethod, setSelectedShippingMethod] =
    useState("standard");

  const customerOrdersUseExpeditedShipping = useSelector(
    (state) =>
      state.profile.shop.config_flags
        .SHOP_CUSTOMER_ORDERS_USE_EXPEDITED_SHIPPING
  );

  useEffect(() => {
    dispatch(getShopSettingsThunk());
  }, [dispatch]);

  const [isAutomaticPayments, setIsAutomaticPayments] = useState(
    profileHook.isVIPPlan()
  );

  const [openPaywallUpgradeModal, setOpenPaywallUpgradeModal] = useState(false);

  useEffect(() => {
    setIsAutomaticPayments(shop.config_flags.SHOP_AUTO_PAY_CUSTOMER_ORDERS);
  }, [shop.config_flags.SHOP_AUTO_PAY_CUSTOMER_ORDERS]);

  useEffect(() => {
    if (shopSettings?.default_shipping_method) {
      setSelectedShippingMethod(shopSettings.default_shipping_method);
    }
  }, [shopSettings?.default_shipping_method]);

  useEffect(() => {
    if (customerOrdersUseExpeditedShipping === "True") {
      setSelectedShippingMethod("expedited");
    } else {
      setSelectedShippingMethod("standard");
    }
  }, [customerOrdersUseExpeditedShipping]);

  useEffect(() => {
    if (!profileHook.isVIPPlan()) {
      setIsAutomaticPayments(false);
    }
  }, [profileHook.isVIPPlan, setOpenPaywallUpgradeModal]);

  const { data: preferences } = useQuery(
    QUERY.getShopPreferences,
    getShopPreferences,
    {
      onSuccess: (data) => {
        setSelectedTransports(data?.data?.[0]?.preferred_transports ?? []);
      },
    }
  );

  const { data: transports } = useQuery(QUERY.getTransports, getTransports);

  useEffect(() => {
    setOutOfStockUnits(preferences?.data?.[0]?.metadata?.min_inventory);
  }, [preferences]);

  const onSaveStockInventory = async () => {
    const id = preferences?.data?.[0]?.id;

    await updateShopPreferences(id, {
      metadata: { min_inventory: Number(outOfStockUnits) },
    });

    setIsEditingUnits(false);
  };

  const renderLowInventoryInput = () => {
    if (isEditingUnits) {
      return (
        <Box className={styles.editRow}>
          <input
            value={outOfStockUnits}
            onChange={(e) => setOutOfStockUnits(e.target.value)}
            type="number"
            min={0}
            className={styles.editStockInput}
          />
          <Text
            onClick={onSaveStockInventory}
            variant="p"
            className={cn(styles.description, styles.editStock)}
          >
            Save
          </Text>
          <Text
            onClick={() => setIsEditingUnits(false)}
            variant="p"
            className={cn(styles.description, styles.editStock)}
          >
            Cancel
          </Text>
        </Box>
      );
    }

    return (
      <Text
        onClick={() => setIsEditingUnits(true)}
        variant="p"
        className={cn(styles.description, styles.editStock)}
      >
        {outOfStockUnits} units.
      </Text>
    );
  };

  const handleChangeTransport = async (event, transport) => {
    const { checked } = event.target;
    const id = preferences?.data?.[0]?.id;

    if (checked) {
      const newTransports = [...selectedTransports, transport?.id];
      setSelectedTransports(newTransports);
      await updateShopPreferences(id, {
        preferred_transports: newTransports,
      });
    } else {
      const newTransports = selectedTransports.filter(
        (value) => value !== transport?.id
      );
      setSelectedTransports(newTransports);
      await updateShopPreferences(id, {
        preferred_transports: newTransports,
      });
    }
  };

  const renderTransports = () => {
    if (!transports?.data && !preferences?.data) return null;

    return transports?.data.map((transport) => {
      const isChecked = selectedTransports?.includes(transport?.id);

      return (
        <IOSSwitch
          checked={isChecked}
          onChange={(e) => handleChangeTransport(e, transport)}
        />
      );
    });
  };

  const handleChangeOrderPayments = async (event) => {
    if (!profileHook.isVIPPlan()) {
      setOpenPaywallUpgradeModal(true);
      return;
    }

    const { checked } = event.target;
    try {
      await dispatch(setAutomaticPaymentsThunk(checked));
    } catch (error) {
      setIsAutomaticPayments(!checked);
    }
  };

  const renderOrderPayments = () => (
    <IOSSwitch
      checked={isAutomaticPayments}
      onChange={handleChangeOrderPayments}
    />
  );

  const handleShippingMethodChange = async (newMethod) => {
    const isExpedited = newMethod === "expedited";
    setSelectedShippingMethod(newMethod);

    try {
      await dispatch(setCustomerOrdersShippingMethodThunk(isExpedited));

      dispatch(
        updateShippingMethod({
          shipping_method: newMethod,
          type: newMethod,
          price:
            newMethod === "standard"
              ? shopSettings?.standard_shipping_cost
              : shopSettings?.expedited_shipping_cost,
          transit_time:
            newMethod === "standard"
              ? shopSettings?.standard_transit_time
              : shopSettings?.expedited_transit_time,
          isDefault: true,
        })
      );
    } catch (error) {
      console.error("Error updating shipping method:", error);
      setSelectedShippingMethod(currentShippingMethod);
    }
  };

  if (!transports?.data && !preferences?.data) return null;

  return (
    <div className={styles.settingsView}>
      <PageHeader title="My Account" />
      <AccountNav />
      <AccountCard>
        {/* NOTIFICATIONS SETTINGS */}
        <Box>
          <Text variant="title" mb={2}>
            Settings
          </Text>
        </Box>
        <Box className={styles.innerCard}>
          <Box>
            <Text variant="subtitle" mb={0.5}>
              Notifications
            </Text>
            <Text variant="description">
              Blanka may still send you notifications outside of these settings
              (eg. shipping status updates, order payments, etc).
            </Text>
          </Box>

          <Box className={styles.notificationsList}>
            <Box className={styles.notificationsListRow}>
              <Box />
              <Box>
                <Text className={styles.settingColumn}>IN APP</Text>
              </Box>
              <Box>
                <Text className={styles.settingColumn}>EMAIL</Text>
              </Box>
            </Box>

            <Box className={styles.notificationsListRow}>
              <Box>
                <Text variant="h3" className={styles.settingTitle}>
                  Low inventory
                </Text>
                <Text variant="p" className={styles.description}>
                  Notify me when inventory is less than{" "}
                  {renderLowInventoryInput()}
                </Text>
              </Box>
              {renderTransports()}
            </Box>
          </Box>
        </Box>

        <Box className={styles.separator} />

        {/* OTHER SETTINGS */}
        <Box className={styles.innerCard}>
          <Box>
            <Text variant="subtitle" mb={0.5}>
              Other
            </Text>
          </Box>

          <Box className={styles.orderPaymentsList}>
            <Box className={styles.orderPaymentsListRow}>
              <Box />
              <Box>
                <Text className={styles.settingColumn}>ENABLE</Text>
              </Box>
            </Box>

            <Box className={styles.orderPaymentsListRow}>
              <Box>
                <Text variant="h3" className={styles.settingTitle}>
                  Order payments
                </Text>
                <Text variant="p" className={styles.description}>
                  When a customer order is received, automatically pay for that
                  order and begin order fulfillment.
                </Text>
              </Box>
              {renderOrderPayments()}
            </Box>

            <Box className={styles.shippingListRow}>
              <Box>
                <Text variant="h3" className={styles.settingTitle}>
                  Shipping method
                </Text>
                <Text variant="p" className={styles.description}>
                  Select the default for all customer orders.{" "}
                  <a
                    target="_blank"
                    href="https://faq.blankabrand.com/en/articles/4880229-how-much-does-shipping-cost"
                    rel="noreferrer"
                  >
                    Shipping Rates
                  </a>
                </Text>
              </Box>
              <FormControl size="small" className={styles.dropdownFormControl}>
                <FormDropdown
                  control={control}
                  name="shippingMethod"
                  options={["standard", "expedited"]}
                  value={selectedShippingMethod}
                  defaultValue="standard"
                  placeholder=""
                  onChange={handleShippingMethodChange}
                  flagSelect={false}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
      </AccountCard>
      <PaywallUpgradeModal
        open={openPaywallUpgradeModal}
        handleClose={() => setOpenPaywallUpgradeModal(false)}
        planId={shop.plan}
        handlePlanChange={() => onOpenModal()}
        bodyText="To get access to this feature, please upgrade your plan to a VIP plan."
      />
    </div>
  );
}
