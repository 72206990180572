export const FILTER_ALL = "all";
export const FILTER_EYE = "eyes";
export const FILTER_LIP = "lips";
export const FILTER_FACE = "face";
export const FILTER_BRUSH = "brushes";
export const FILTER_NEW = "new";
export const FILTER_SKIN_CARE = "beauty & skincare";
export const FILTER_PREMIUM = "premium";
export const FILTER_MENS = "mens products";
export const FILTER_TRAVEL_FRIENDLY = "travel friendly";
export const FILTER_VEGAN = "vegan";
export const FILTER_ACCESSORIES = "accessories";
export const FILTER_BENEFITS = "product benefit";
export const FILTER_OUT_OF_STOCK = "out of stock";
export const FILTER_DISCONTINUED = "discontinued";
