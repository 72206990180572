import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Material UI Components
import { Stack } from "@mui/material";

// custom components and constants:
import AccountNav from "components/AccountNav";
import AccountCard from "components/Cards/AccountCard";
import PageHeader from "components/PageHeader/PageHeader";
import Text from "components/Text";

import AccountAddressesForm from "components/Forms/AccountAddressesForm";
import useAccountAddresses from "hooks/useAccountAddresses";

import styles from "./AccountAddressesUpdateView.module.scss";

export default function AccountAddressesUpdateView() {
  const { isEditing, isAdding, shippingMethod } = useSelector(
    (state) => state.accountAddresses
  );

  const navigate = useNavigate();
  const {
    handleOnSubmitForm,
    handleOnCancelForm,
    handleOnSelectShippingMethod,
  } = useAccountAddresses();

  const onSubmit = async (data) => {
    try {
      await handleOnSubmitForm(data);
      navigate("/profile/addresses");
    } catch (error) {
      console.error("Failed to submit form", error);
    }
  };

  const onCancel = () => {
    handleOnCancelForm();
    navigate("/profile/addresses");
  };

  return (
    <div className={styles.accountAddressesUpdateView}>
      <PageHeader title="My Account" />
      <AccountNav />
      <AccountCard>
        <Stack className={styles.stackContainer}>
          <Text variant="h1" className={styles.title}>
            {isEditing && "Edit Address"}
            {isAdding && "New Address"}
          </Text>
          <AccountAddressesForm onSubmit={onSubmit} onCancel={onCancel} />
        </Stack>
      </AccountCard>
    </div>
  );
}
