import React from "react";
import { useSelector } from "react-redux";

// MUI
import { Box, Stack } from "@mui/material";

// third party libraries
import moment from "moment";

// custom components and constants:
import PageHeader from "components/PageHeader/PageHeader";

import AccountCard from "components/Cards/AccountCard";
import Text from "components/Text";

// import AccountPasswordView from "views/AccountPasswordView";
import AccountProfileForm from "components/Forms/AccountProfileForm";

import { ArrowBack } from "@mui/icons-material";
import styles from "./AccountPersonalInformationView.module.scss";

function AccountPersonalInformation() {
  const profile = useSelector((state) => state.profile);

  const handleBackBtn = () => {
    window.history.back();
  };

  return (
    <div className={styles.accountPersonalInformation}>
      <PageHeader title="My Account" />
      <Box className={styles.backBtn} onClick={handleBackBtn}>
        <ArrowBack /> Back
      </Box>

      <AccountCard>
        <Box className={styles.boxContainer}>
          <Stack width="100%">
            <Text className={styles.bodyTitle} variant="h1">
              Personal information
            </Text>
            <AccountProfileForm />
          </Stack>
          <Text className={styles.headerDetail}>
            <strong>Account since:</strong>{" "}
            {moment(profile?.profile.date_joined).format("MMM Do YYYY")}
          </Text>
        </Box>
      </AccountCard>
    </div>
  );
}

export default AccountPersonalInformation;
