import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { QUERY, payCustomerOrder } from "api";
import queryClient from "api/queryClient";

// mui:
import HelpIcon from "@mui/icons-material/Help";
import {
  Box,
  Checkbox,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckoutConfirmPayment from "views/CheckoutPaymentView/components/CheckoutConfirmPayment";

// components:
import Button from "components/Buttons/Button";
import ConfirmOrderModal from "components/Modals/ConfirmOrderModal";
import OrderWithIssuesModal from "components/Modals/OrderWithIssuesModal";
import {
  GenericSuccessNotification,
  NoBillingMethodError,
} from "components/Notifications";
import Text from "components/Text";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";

import {
  CART_TYPE_CUSTOMER,
  FEES_CONSTANT,
  FEES_PERCENTAGE,
  ORDER_STATUS_PAYMENT_REQUIRED,
  PRODUCT_TYPE_UNBRANDED,
} from "constants/constants";

import useCustomerOrders from "hooks/useCustomerOrders";

import CartTotals from "components/CartTotals/CartTotals";

import cn from "utils/cn";
import { AUTOMATIC_PAYMENT_TAG } from "constants/notifications";

import AutomaticPaymentStatus from "components/Labels/AutomaticPaymentStatus/AutomaticPaymentStatus";
import OrderTableItem from "./OrderTableItem";

import styles from "./OrderTable.module.scss";

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));

function OrderTable(props) {
  const { order } = props;
  const { items, order_type: orderType, has_error: hasError, status } = order;

  const isAutomaticPaymentEnabled =
    order.tags && order.tags.includes
      ? order.tags.includes(AUTOMATIC_PAYMENT_TAG)
      : false;

  const cartBill = {
    discount: order.discount,
    subtotal: order.subtotal,
    subtotal_with_savings: order.subtotal_with_savings || order.subtotal,
    total_with_savings: order.total_cost_with_savings || order.total,
    total: order.total,
    total_discount: order.total_discount || 1,
    fees: order.fees,
    shipping: {
      shipping_cost: order.shipping_cost,
    },
    type: order.order_type,
  };

  const [clientSecret, setClientSecret] = useState(null);
  const [nextActionType, setNextActionType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [brandedBoxItems, setBrandedBoxItems] = useState([]);

  const [fullfilledByBlanka, setFilledByBlanka] = useState(
    order.use_own_inventory || false
  );

  const [shipping, setShipping] = useState();
  const [subtotal, setSubtotal] = useState();
  const [fees, setFees] = useState();

  const shop = useSelector((state) => state.profile.shop);
  const payOrderMutation = useMutation(payCustomerOrder);

  const [displayModalAlert, setDisplayModalAlert] = useState(false);
  const [openOrderWithIssuesModal, setOpenOrderWithIssuesModal] =
    useState(displayModalAlert);

  const [getPickNPackFee] = useCustomerOrders();

  // filter out discontinued or out of stock orders for alert
  const handleFilterOrders = (rawOrders) => {
    const requirePaymentOrders = rawOrders.filter(
      (order) => order.status === ORDER_STATUS_PAYMENT_REQUIRED
    );

    const filtered = requirePaymentOrders.filter((order) =>
      order.items.some(
        (item) =>
          item.product?.product_base?.is_discontinued ||
          item.product?.product_base?.out_of_stock
      )
    );

    setDisplayModalAlert(filtered.length > 0);
  };

  useEffect(() => {
    handleFilterOrders([order]);
  }, [order]);

  const handleAccept = () => {
    setOpenOrderWithIssuesModal(false);
    setDisplayModalAlert(false);
    setConfirmation(true);
  };

  useEffect(() => {
    if (!brandedBoxItems.length > 0) {
      return;
    }

    setSubtotal(order.order_sub_total);
    setShipping(order.order_shipping_total);
    setFees(order.order_fees);
  }, [brandedBoxItems]);

  useEffect(() => {
    if (fullfilledByBlanka) {
      setSubtotal(0);
      setFees(shipping * FEES_PERCENTAGE + FEES_CONSTANT);
    } else {
      setSubtotal(order.order_sub_total);
      setShipping(order.order_shipping_total);
      setFees(order.order_fees);
    }
  }, [fullfilledByBlanka]);

  const handlePaymentSuccess = () => {
    toast.success(
      <GenericSuccessNotification text="Your customer order has been placed" />
    );
    queryClient.refetchQueries([QUERY.getCustomerOrders]).then(() => {
      setLoading(false);
    });
  };

  const handleSetConfirmation = async () => {
    setLoading(true);

    if (displayModalAlert) {
      setOpenOrderWithIssuesModal(true);
      setConfirmation(false);
      setLoading(false);
      return;
    }

    setConfirmation(true);
    setLoading(false);
  };

  const handlePlaceOrder = async (id) => {
    setConfirmation(false);
    setLoading(true);

    payOrderMutation.mutate(
      {
        orderID: id,
        boxData: [],
        useOwnInventory: fullfilledByBlanka,
      },
      {
        onSuccess: (response) => {
          if (response.data.requires_action) {
            setClientSecret(response.data.client_secret);
            setNextActionType(response.data.next_action?.type || null);
            setLoading(true);
            return;
          }

          toast.success(
            <GenericSuccessNotification text="Your customer order has been placed" />
          );
          queryClient.refetchQueries([QUERY.getCustomerOrders]);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
          toast.error(<NoBillingMethodError />);
        },
      }
    );
  };
  return (
    <TableContainer className={styles.orderTable}>
      <Table sx={{ border: "none" }}>
        <TableHead className={styles.tableHeader}>
          <TableRow>
            <TableCell className={styles.columnName}>Product</TableCell>
            <TableCell className={styles.columnName} width="20px">
              Qty
            </TableCell>
            <TableCell className={styles.columnName} width="80px">
              Unit Cost
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items?.map((item) => (
            <OrderTableItem
              key={item.sku}
              item={item}
              order={order}
              fullfilledByBlanka={!fullfilledByBlanka}
            />
          ))}
        </TableBody>
      </Table>
      {orderType === CART_TYPE_CUSTOMER ? (
        <>
          <Divider orientation="horizontal" className={styles.divider} />
          <Stack className={styles.warning}>
            <Box className={styles.warningBox}>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <a
                target="__blank"
                href="https://faq.blankabrand.com/en/articles/6524252-what-does-it-mean-to-fulfil-using-my-inventory-at-blanka"
              >
                <HelpIcon className={styles.warningHelpIcon} />
              </a>

              <Box
                className={cn(
                  !order.is_fulfillable_with_stored_merchant_inventory &&
                    styles.disabled,
                  styles.checkBoxWrapper
                )}
              >
                {order.status === ORDER_STATUS_PAYMENT_REQUIRED ? (
                  <Checkbox
                    className={styles.warningCheckIcon}
                    disabled={
                      !order.is_fulfillable_with_stored_merchant_inventory
                    }
                    checked={fullfilledByBlanka}
                    onChange={(event) => {
                      setFilledByBlanka(event.target.checked);
                    }}
                  />
                ) : (
                  order.use_own_inventory && (
                    <CheckIcon className={`icon--check ${styles.checkIcon}`} />
                  )
                )}

                <Text className={styles.warningText}>
                  FULFILL USING MY INVENTORY AT BLANKA
                </Text>
              </Box>
            </Box>
          </Stack>
          <Divider orientation="horizontal" className={styles.divider} />
        </>
      ) : (
        <Divider orientation="horizontal" className={styles.divider} />
      )}

      <Box
        className={
          orderType === CART_TYPE_CUSTOMER &&
          status === ORDER_STATUS_PAYMENT_REQUIRED
            ? styles.total
            : `${styles.total} ${styles.adjustPadding}`
        }
      >
        <Box className={styles.boxContainer}>
          {orderType !== CART_TYPE_CUSTOMER ? (
            <CartTotals cart={cartBill} step="invoice" />
          ) : (
            <>
              <div className={styles.customerOrderCart}>
                <div className="totals-container__values-container">
                  <Text className="text__totals text--nunito ">Subtotal</Text>
                  <Text className="text__totals text--bold text--nunito">
                    ${Number(subtotal).toFixed(2)}
                  </Text>
                </div>

                {shop.has_pick_n_pack && getPickNPackFee(order?.items) > 0 && (
                  <div className="totals-container__values-container">
                    <Text className="text__totals text--nunito ">
                      Pick & pack Fee
                    </Text>
                    <Text className="text__totals text--bold text--nunito">
                      ${getPickNPackFee(order?.items)}
                    </Text>
                  </div>
                )}

                <div className="totals-container__values-container">
                  <Text className="text__totals text--nunito ">Shipping</Text>
                  <Text className="text__totals text--bold text--nunito">
                    ${Number(shipping).toFixed(2)} (order.shipping_method)
                  </Text>
                </div>
                <div className="pb-10 totals-container__values-container">
                  <Text className="text__totals text--nunito ">
                    Processing Fee
                  </Text>
                  <Text className="text__totals text--bold text--nunito">
                    $
                    {(
                      Number(fees) + Number(getPickNPackFee(order?.items))
                    ).toFixed(2)}
                  </Text>
                </div>

                <div className="totals-container__values-container totals-container--border-top">
                  <Text className="text__totals text--nunito text--left">
                    Total
                  </Text>
                  <Text className="text__totals text--bold text--nunito">
                    $
                    {(
                      Number(subtotal) +
                      Number(shipping) +
                      Number(fees) +
                      Number(getPickNPackFee(order?.items))
                    ).toFixed(2)}
                  </Text>
                </div>
              </div>
              <div className={styles.automaticPaymentStatus}>
                {/* TODO: For now we don't handle error msgs!!! */}
                {isAutomaticPaymentEnabled && (
                  <AutomaticPaymentStatus status={false} />
                )}
              </div>
            </>
          )}
        </Box>
      </Box>
      {orderType === CART_TYPE_CUSTOMER &&
        status === ORDER_STATUS_PAYMENT_REQUIRED && (
          <>
            <Box padding="0px 15px 40px 15px">
              <Button
                loading={loading}
                disabled={hasError}
                onClick={handleSetConfirmation}
                variant="contained"
                color="primary"
                fullWidth
                hasChevron
              >
                Place Order
              </Button>

              {hasError && (
                <Text
                  className="text--nunito text--error text__order-error"
                  sx={{ paddingTop: "10px" }}
                >
                  Oops! There is an issue with the shipping address on this
                  order. Please contact us to resolve this.
                </Text>
              )}
            </Box>
            <Elements stripe={stripePromise}>
              <CheckoutConfirmPayment
                nextActionType={nextActionType}
                clientSecret={clientSecret}
                handlePaymentSuccess={handlePaymentSuccess}
                handlePaymentError={() => {
                  setNextActionType();
                  setClientSecret();
                  setLoading(false);
                }}
              />
            </Elements>
          </>
        )}

      <OrderWithIssuesModal
        open={openOrderWithIssuesModal}
        onClose={() => setOpenOrderWithIssuesModal(false)}
        onAccept={() => handleAccept()}
      />

      <ConfirmOrderModal
        open={confirmation && !displayModalAlert}
        handleClose={() => setConfirmation(false)}
        onConfirm={() => handlePlaceOrder(order.id)}
        price={(
          Number(subtotal) +
          Number(shipping) +
          Number(fees) +
          Number(getPickNPackFee(order?.items))
        ).toFixed(2)}
      />
    </TableContainer>
  );
}

export default OrderTable;
