import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import DashboardHeaderPopover from "components/Dashboard/DashboardHeaderPopover";
import HeaderCartIcon from "components/Icons/HeaderCartIcon/HeaderCartIcon";
import HeartIcon from "components/Icons/HeartIcon";
import NotificationBellIcon from "components/Icons/NotificationBellIcon";
import FreeTrialTag from "components/Labels/FreeTrialTag";
import StartTrialTag from "components/Labels/StartTrialTag";
import NotificationCenter from "components/NotificationCenter/NotificationCenter";
import PublishedDiscontinuedProductNotification from "components/Notifications/PublishedDiscontinuedProductNotification/PublishedDiscontinuedProductNotification";
import Text from "components/Text";
import UserMenu from "components/UserMenu";
import { CART_TYPE_BRAND_PLATE } from "constants/constants";
import { useAnalytics, useProfile } from "hooks";
import { useSelector } from "react-redux";
import ROUTE_PATHS from "routes/routePaths";
import cn from "utils/cn";
import styles from "./AppBar.module.scss";

const drawerWidth = 220;
const AppBarMui = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function AppBar({ open, handleDrawerOpen }) {
  const { isOnTrial, hasAvailableTrial } = useProfile();
  const { pathname } = useLocation();

  const analytics = useAnalytics();
  const cart = useSelector((state) => state.cart);

  const isFavorites = useMemo(
    () => pathname.includes(ROUTE_PATHS.FAVORITES),
    [pathname]
  );

  return (
    <AppBarMui
      position="fixed"
      className={styles.appBar}
      open={open}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Text
          color="dark-gray"
          fontSize={12}
          className="text--georgia text--bold hidden-mobile target__app-bar--latest-from-the-blog"
        >
          Latest from the blog: &nbsp;
        </Text>
        <a
          className={`${styles.textBlogLink} target__app-bar--beyond-the-brand-blog-link`}
          target="_blank"
          href="https://blankabrand.com/blogs/beyond-the-brand-beauty-blog"
          rel="noreferrer"
          onClick={() =>
            analytics.sendEvent(analytics.LINK_CLICKED_LATEST_FROM_THE_BLOG, {})
          }
        >
          Beyond The Brand Blog
        </a>
        <Box sx={{ flexGrow: 1 }} />

        <Box className={styles.center} style={{ display: "relative" }}>
          {isOnTrial() ? (
            <FreeTrialTag />
          ) : (
            hasAvailableTrial() && <StartTrialTag />
          )}

          <Box className={styles.leftActions}>
            <DashboardHeaderPopover />
            <NotificationCenter />
            <Link className={styles.heartIcon} to={ROUTE_PATHS.FAVORITES}>
              <HeartIcon size={28} isActive={isFavorites} />
            </Link>
            {cart.type !== CART_TYPE_BRAND_PLATE && (
              <Link to="/cart" className={styles.cartIcon} data-cy="cart-icon">
                <HeaderCartIcon hasItems={pathname.includes("cart")} />
                {cart?.items?.length > 0 && (
                  <Box
                    className={cn(
                      styles.iconBadge,
                      cart?.items?.length > 10 && styles.iconBadgeBig
                    )}
                    px={cart?.items?.length > 99 && "4px"}
                  >
                    {cart?.items?.length}
                    {cart?.items?.length > 99 && "+"}
                  </Box>
                )}
              </Link>
            )}
            <UserMenu />
          </Box>
        </Box>
      </Toolbar>
    </AppBarMui>
  );
}

AppBar.propTypes = {
  handleDrawerOpen: PropTypes.func,
};

AppBar.defaultProps = {
  handleDrawerOpen: () => {},
};

export default AppBar;
