export const NOTIFICATION_DEFAULT_ICON = {
  low: "bell",
  normal: "bell",
  high: "alert",
};

export const NOTIFICATION_CENTER_FILTER = [
  { label: "All updates", value: "all" },
  { label: "New product launches", value: "new" },
  { label: "Out of stock", value: "oos" },
  { label: "Discontinued products", value: "discontinued" },
  { label: "Order updates", value: "other" },
];

export const AUTOMATIC_PAYMENT_TAG = "ORDER_AUTOMATICALLY_PAID";

// for future TS
// type NOTIFICATION_URGENCY_TYPE = "high" | "normal" | "low";
// type NOTIFICATION_ICON = 'box' | 'alert | 'truck' | 'bag' | 'bell' | 'wand' | 'cart' | 'stop'
// type NOTIFICATION_DEFAULT_ICON = {
//     low: 'bell',
//     normal: 'bell',
//     high: 'alert'
// }

// type NOTIFICATION_DATA_SAMPLE = {
//     urgency: NOTIFICATION_URGENCY_TYPE;
//     isRead: boolean;
//     icon: NOTIFICATION_ICON;
//     title: string;
//     description: string;
//     navigateTo: string;
//     actionText: string;
//     createdAt: string;
// }

const NOTIFICATION_DATA_SAMPLE = {
  urgency: "",
  isRead: false,
  icon: "bell",
  title: "",
  description: "",
  navigateTo: "",
  actionText: "",
  createdAt: "",
};

export const NOTIFICATIONS = [
  {
    id: 1,
    urgency: "low",
    isRead: true,
    icon: "box",
    title: "Your inventory order is being processed! 🎉",
    description: "",
    navigateTo: "/inventory-orders",
    actionText: "Order #9413",
    createdAt: new Date().toISOString(),
  },
  {
    id: 2,
    urgency: "high",
    isRead: true,
    icon: "alert",
    title: "Attention! Your payment has failed.",
    description:
      "Update your payment method to avoid delays to your order fulfillment time.",
    navigateTo: "/cart",
    actionText: "Fix it now",
    createdAt: new Date().toISOString(),
  },
  {
    id: 3,
    urgency: "low",
    isRead: true,
    icon: "truck",
    title: "Your inventory order is being processed! 🎉",
    description: "",
    navigateTo: "/inventory-orders",
    actionText: "Order #9413",
    createdAt: new Date().toISOString(),
  },
  {
    id: 4,
    urgency: "low",
    isRead: true,
    icon: "bag",
    title: "Your inventory order is being processed! 🎉",
    description: "",
    navigateTo: "/inventory-orders",
    actionText: "Order #9413",
    createdAt: new Date().toISOString(),
  },
  {
    id: 5,
    urgency: "high",
    isRead: false,
    icon: "bell",
    title: "Woohoo! Your order has been shipped! 📦",
    description: "",
    navigateTo: "/sample-orders",
    actionText: "Order #9412",
    createdAt: new Date().toISOString(),
  },
  {
    id: 6,
    urgency: "high",
    isRead: false,
    icon: "wand",
    title: "Woohoo! Your order has been shipped! 📦",
    description: "",
    navigateTo: "/sample-orders",
    actionText: "Order #9412",
    createdAt: new Date().toISOString(),
  },
  {
    id: 7,
    urgency: "high",
    isRead: false,
    icon: "cart",
    title: "Woohoo! Your order has been shipped! 📦",
    description: "",
    navigateTo: "/sample-orders",
    actionText: "Order #9412",
    createdAt: new Date().toISOString(),
  },
  {
    id: 8,
    urgency: "high",
    isRead: false,
    icon: "block",
    title: "Woohoo! Your order has been shipped! 📦",
    description: "",
    navigateTo: "/sample-orders",
    actionText: "Order #9412",
    createdAt: new Date().toISOString(),
  },
  {
    id: 9,
    urgency: "high",
    isRead: false,
    icon: "wand",
    title: "Woohoo! Your order has been shipped! 📦",
    description: "",
    navigateTo: "/sample-orders",
    actionText: "Order #9412",
    createdAt: new Date().toISOString(),
  },
  {
    id: 10,
    urgency: "high",
    isRead: false,
    icon: "cart",
    title: "Woohoo! Your order has been shipped! 📦",
    description: "",
    navigateTo: "/sample-orders",
    actionText: "Order #9412",
    createdAt: new Date().toISOString(),
  },
  {
    id: 11,
    urgency: "high",
    isRead: false,
    icon: "block",
    title: "Woohoo! Your order has been shipped! 📦",
    description: "",
    navigateTo: "/sample-orders",
    actionText: "Order #9412",
    createdAt: new Date().toISOString(),
  },
];
