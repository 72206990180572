import { Clear, SearchOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { getProductSuggestions } from "api/endpoints/productsAPi";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import cn from "utils/cn";

import styles from "./SearchInput.module.scss";

function SearchInput({
  listClassName,
  rootClassName,
  inputClassName,
  isCollapsable,
  onSearch,
  value,
  placeholder = "Search term",
  customOnChange,
  customIsActive,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    if (isCollapsable) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (isCollapsable) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    if (customIsActive) {
      customIsActive(isHovered || isFocused);
    }
  }, [customIsActive, isHovered, isFocused]);

  // If not collapsable, ensure isHovered is always true
  useEffect(() => {
    if (!isCollapsable) {
      setIsHovered(true);
    }
  }, [isCollapsable]);

  const { isLoading } = useQuery(
    ["suggestions", inputValue],
    () => getProductSuggestions(),
    {
      enabled: inputValue.length >= 2 && !customOnChange,
      keepPreviousData: true,
      onSuccess: (response) => {
        if (response?.data) {
          // Filter suggestions to only include items that match the input value
          const filteredSuggestions = response?.data
            .filter((suggestion) =>
              suggestion.product_name
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            )
            .slice(0, 10);

          if (filteredSuggestions.length === 0) {
            return;
          }

          setSuggestions(filteredSuggestions);
        }
      },
    }
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleDivClick = () => {
    setIsHovered(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const clearInput = (e) => {
    e.stopPropagation();
    setInputValue("");
    setShowSuggestions(false);
    setSuggestions([]);
    if (customOnChange) {
      customOnChange("");
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (inputValue.length >= 2) {
      setShowSuggestions(true);
    }
  };
  const handleBlur = () => setIsFocused(false);

  const handleSearch = async () => {
    setShowSuggestions(false);
    if (inputValue.trim() && !customOnChange) {
      if (onSearch) {
        await onSearch(inputValue.trim());
      } else {
        navigate(`/search?search_term=${inputValue.trim()}`);
      }
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    const searchTerm = suggestion.product_name.trim();
    setInputValue(searchTerm);
    if (onSearch) {
      await onSearch(searchTerm);
    } else {
      navigate(`/search?search_term=${encodeURIComponent(searchTerm)}`);
    }
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !customOnChange) {
      if (selectedIndex >= 0 && selectedIndex < suggestions.length) {
        handleSuggestionClick(suggestions[selectedIndex]);
      } else if (inputValue) {
        handleSearch();
      }
    } else if (showSuggestions && suggestions.length > 0) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectedIndex((prev) =>
          prev < suggestions.length - 1 ? prev + 1 : 0
        );
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectedIndex((prev) =>
          prev > 0 ? prev - 1 : suggestions.length - 1
        );
      }
    }
  };

  const handleInputChange = (e) => {
    const newTerm = e.target.value;
    setInputValue(newTerm);
    if (newTerm) {
      setShowSuggestions(newTerm.length >= 2);
    } else {
      setShowSuggestions(false);
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleDivClick}
      onInput={handleInputChange}
      className={cn(
        styles.searchInputContainer,
        (isHovered || isFocused) && styles.visible,
        isFocused && styles.focused,
        showSuggestions && suggestions.length > 0 && styles.withSuggestions,
        !isCollapsable && styles.expanded,
        rootClassName && rootClassName
      )}
      data-testid="SearchInput"
    >
      <div className={styles.iconContainer}>
        <SearchOutlined />
      </div>
      <input
        ref={inputRef}
        className={cn(
          styles.input,
          isFocused && styles.focused,
          isHovered && styles.hovered,
          inputClassName && inputClassName
        )}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => {
          if (customOnChange) {
            customOnChange(e.target.value);
          }
          setInputValue(e.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
      {inputValue && (isHovered || isFocused) && (
        <div className={styles.clearIcon} onClick={clearInput}>
          <Clear />
        </div>
      )}
      {(isHovered || isFocused) && (
        <button
          type="button"
          className={styles.searchButton}
          onClick={handleSearch}
        >
          {isLoading ? <CircularProgress size={12} /> : "SEARCH"}
        </button>
      )}
      {showSuggestions && suggestions?.length > 0 && isHovered && (
        <div className={cn(styles.suggestions, listClassName && listClassName)}>
          {suggestions.map((suggestion, index) => (
            <div
              key={suggestion.product_name}
              className={cn(
                styles.suggestionItem,
                selectedIndex === index && styles.selected
              )}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.product_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchInput;
