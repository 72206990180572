/* eslint-disable import/no-cycle */
import { PRODUCT_TYPE_UNBRANDED } from "constants/constants";
import { FILTER_PREMIUM } from "constants/filters";

import apiService from "api/apiService";

export const getProducts = (
  category = FILTER_PREMIUM,
  productType = PRODUCT_TYPE_UNBRANDED,
  page = 1
) =>
  apiService.get(
    `/products/?page=${page}&product_type=${productType}${
      category !== "all" ? `&category=${category}` : ""
    }`
  );

export const getProductsV2 = (params = {}) => {
  const cleanedParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value != null)
  );

  const queryString = new URLSearchParams(cleanedParams).toString();

  // try to fix for the search:
  const searchTerm = cleanedParams.search_term;

  if (searchTerm) {
    // If search_term is present, use the /search endpoint
    return apiService.get(`/v2/products/search/?${queryString}`);
  }
  // Otherwise, use the original endpoint
  return apiService.get(`/v2/products/?${queryString}`);
};

export const getProduct = (productId) =>
  apiService.get(`/products/${productId}/`);

// add query param for this URL
export const getProductV2 = (productId, productType = PRODUCT_TYPE_UNBRANDED) =>
  apiService.get(`/v2/products/${productId}?product_type=${productType}`);

export const getProductMockupsV2 = (
  productId,
  productType = PRODUCT_TYPE_UNBRANDED
) =>
  apiService.get(
    `/v2/products/${productId}?product_type=${productType}&mockups=true`
  );

export const getDiscount = (productId, quantity) =>
  apiService.get(`/discounts/${productId}/?quantity=${quantity}`);

export const getMyProducts = () => apiService.get(`/shop-products/`);

export const addProduct = (payload) =>
  apiService.post("/shop-product/add-product/", payload);

export const publishProduct = (id) =>
  apiService.post("/shop/publish_product/", { product_id: id });

export const unpublishProduct = (id) =>
  apiService.post("/shop/unpublish_product/", { product_id: id });

export const deleteShopProduct = (id) =>
  apiService.delete(`/shop-products/${id}/`);

export const favoriteProduct = (payload) =>
  apiService.post("/v2/products/favorite/", payload);

export const unfavoriteProduct = (payload) =>
  apiService.delete("/v2/products/favorite/", { data: payload });
export const getProductSuggestions = () =>
  apiService.get("/products/get-autocomplete-suggestions");
