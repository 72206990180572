import StarIcon from "@mui/icons-material/Star";
import { CrownIcon } from "components/Icons";
import ExpiringTag from "components/Labels/ExpiringTag";
import LowStockTag from "components/Labels/LowStockTag";
import OutOfStockTag from "components/Labels/OutOfStockTag";
import Popper from "components/Popper";
import { SMALL } from "constants/breakpoints";
import { FILTER_PREMIUM } from "constants/filters";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FindProductsImageContainer from "../FindProductsImageContainer";
import styles from "./ProductCard.module.scss";
import darkenColor from "./darkenColor";

// next to do:
// make components out of this in order to be reused
// clean up scss styles
// integrate with actual endpoint

function ProductCard({ product, variants = [] }) {
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const [isContentHovered, setIsContentHovered] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(variants?.[0] ?? null);
  const location = useLocation();
  // const [search] = useSearchParams();
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const onNavigateToProductView = () => {
    // const productType = search.get("product_type");

    const params = new URLSearchParams({
      variant: selectedVariant?.sku,
      product_type: selectedVariant?.product_type,
    });

    // needed for saving scroll state and prevUrl when going back to products list
    const currentUrl = `${location.pathname}${location.search}`;
    const scrollPosition = window.scrollY;

    sessionStorage.setItem("prevUrl", currentUrl);
    sessionStorage.setItem("prevScrollPosition", scrollPosition);

    navigate(`/products/${product.slug}?${params.toString()}`);
  };

  useEffect(() => {
    if (variants) {
      setSelectedVariant(variants[0]);
    }
  }, [variants]);

  const renderVariants = () => {
    if (variants) {
      let filteredVariants = variants;
      if (variants.length > 12) {
        filteredVariants = variants.slice(0, 12);
      }

      return filteredVariants.map((variant) => {
        if (variant?.color_code) {
          if (variant?.added_to_my_products) {
            return (
              <Popper
                key={variant.id}
                text={`${variant.color_name} added to My Products`}
              >
                <div
                  onMouseEnter={() => setSelectedVariant(variant)}
                  onClick={onNavigateToProductView}
                  style={{
                    border: `1px solid ${darkenColor(variant?.color_code, 12)}`,
                    background: variant?.color_code,
                  }}
                  className={styles.variantCircle}
                  key={variant?.id}
                >
                  {variant?.added_to_my_products && (
                    <div className={styles.variantSelected}>
                      <img
                        style={{ height: 10, width: 10 }}
                        src="/icon/product-view/product-check.svg"
                        alt="check"
                      />
                    </div>
                  )}
                </div>
              </Popper>
            );
          }

          return (
            <Popper key={variant.id} text={variant.color_name}>
              <div
                onMouseEnter={() => setSelectedVariant(variant)}
                onClick={onNavigateToProductView}
                style={{
                  border: `1px solid ${darkenColor(variant?.color_code, 12)}`,
                  background: variant?.color_code,
                }}
                className={styles.variantCircle}
                key={variant?.id}
              >
                {variant?.added_to_my_products && (
                  <div className={styles.variantSelected}>
                    <img
                      style={{ height: 10, width: 10 }}
                      src="/icon/product-view/product-check.svg"
                      alt="check"
                    />
                  </div>
                )}
              </div>
            </Popper>
          );
        }

        return variant?.added_to_my_products ? (
          <Popper
            key={variant.id}
            text={`${variant.color_name ? `${variant.color_name} added to my products` : "Added to My Products"}`}
          >
            <div
              onMouseEnter={() => setSelectedVariant(variant)}
              onClick={onNavigateToProductView}
              className={styles.variantCircle}
              tabIndex={0}
              role="button"
              aria-label={`View details for ${variant.color_name}`}
            >
              <img
                className={styles.noColorIconBig}
                src="/icon/product-view/no-color.svg"
                alt="No color icon"
              />
              <div className={styles.variantSelected}>
                <img
                  style={{ height: 10, width: 10 }}
                  src="/icon/product-view/product-check.svg"
                  alt="Selected checkmark"
                />
              </div>
            </div>
          </Popper>
        ) : (
          <Popper key={variant.id} text="No color">
            <div
              onMouseEnter={() => setSelectedVariant(variant)}
              onClick={onNavigateToProductView}
              className={styles.variantCircle}
              tabIndex={0}
              role="button"
              aria-label={`View details for ${variant.color_name}`}
            >
              <img
                className={styles.noColorIconBig}
                src="/icon/product-view/no-color.svg"
                alt="No color icon"
              />
            </div>
          </Popper>
        );
      });
    }

    return null;
  };

  const renderVariantsSide = () => {
    if (variants) {
      // If no variants are available, return an empty string
      if (variants.length === 1) {
        return "";
      }

      return variants.slice(0, 5).map((variant, index) => {
        if (variant?.color_code) {
          return (
            <div
              key={variant.id}
              onClick={onNavigateToProductView}
              onMouseEnter={() => setSelectedVariant(variant)}
              style={{
                background: variant?.color_code,
                top: -(index * 4),
                cursor: "pointer",
              }}
              className={styles.variantCircleSide}
            />
          );
        }
        return (
          <img
            key={`no-color-${index + 1}`}
            style={{
              top: -(index * 4),
              position: "relative",
              borderColor: "transparent",
            }}
            className={styles.variantCircleSide}
            src="/icon/product-view/no-color.svg"
            alt="no color"
          />
        );
      });
    }

    return null;
  };

  const isPremium = useMemo(
    () => selectedVariant?.categories.includes(FILTER_PREMIUM),
    [selectedVariant]
  );

  if (!selectedVariant) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => !isMobile && setIsContentHovered(true)}
      onMouseLeave={() => !isMobile && setIsContentHovered(false)}
      onClick={(e) => {
        if (isMobile) {
          e.stopPropagation();
          onNavigateToProductView();
        }
      }}
      className={styles.productCard}
    >
      <div className={styles.productStatusContainer}>
        {selectedVariant?.product_base?.expires_at !== null && (
          <ExpiringTag expiryDate={selectedVariant?.product_base?.expires_at} />
        )}
        {selectedVariant?.product_base?.out_of_stock && (
          <OutOfStockTag
            expiryDate={selectedVariant?.product_base?.out_of_stock_date}
          />
        )}
        {selectedVariant?.product_base?.low_stock && <LowStockTag />}
      </div>
      <div
        style={isContentHovered ? { right: -80 } : { right: 12 }}
        className={styles.productVariantsSide}
      >
        {isPremium && (
          <div className={styles.premiumTag}>
            <StarIcon style={{ height: 10, width: 10 }} />
            Premium
          </div>
        )}
        {renderVariantsSide()}
        {variants?.length > 5 && (
          <span
            style={{ top: -18, width: 12, height: 12 }}
            className={styles.variantSidePlus}
          >
            +
          </span>
        )}
      </div>
      <div onClick={onNavigateToProductView} className={styles.productImages}>
        <FindProductsImageContainer product={selectedVariant} />
      </div>

      <div ref={contentRef} className={styles.productContent}>
        {selectedVariant?.product_base?.vip_only && !isContentHovered && (
          <div className={styles.vipContainer}>
            <CrownIcon isPartialLength />
          </div>
        )}
        <div
          className={`${styles.productVariants} ${isContentHovered ? styles.visible : ""}`}
        >
          {renderVariants()}
          {variants?.length > 12 && (
            <span
              className={styles.variantsPlus}
              onClick={onNavigateToProductView}
            >
              +
            </span>
          )}
        </div>
        <div>
          <span className={styles.productName}>{product?.name}</span>
        </div>
        <div className={styles.productFooter}>
          <div className={styles.productFooterPrice}>
            <div className={styles.priceContainer}>
              <span className={styles.dollarSign}>$</span>
              <span className={styles.price}>{selectedVariant?.cost}</span>
            </div>
            <div className={styles.productMobilePrices}>
              <div className={styles.productSrp}>
                <div className={styles.priceDetailsContainer}>
                  <span
                    style={{ color: "#246071" }}
                    className={styles.dollarSign}
                  >
                    $
                  </span>
                  <span style={{ color: "#246071" }} className={styles.price}>
                    {selectedVariant?.suggested_cost}
                  </span>
                </div>
                <span
                  style={{ color: "#246071" }}
                  className={styles.priceLabel}
                >
                  SRP
                </span>
              </div>
              <div className={styles.productProfit}>
                <div className={styles.priceDetailsContainer}>
                  <span style={{ color: "#FFF" }} className={styles.dollarSign}>
                    $
                  </span>
                  <span style={{ color: "#FFF" }} className={styles.price}>
                    {parseFloat(
                      Number(selectedVariant?.suggested_cost) -
                        Number(selectedVariant?.cost)
                    ).toFixed(2)}
                  </span>
                </div>
                <span style={{ color: "#FFF" }} className={styles.priceLabel}>
                  PROFIT
                </span>
              </div>
            </div>
            {isContentHovered && (
              <div>
                <span className={styles.priceLabel}>unit cost</span>
              </div>
            )}
          </div>
          {isContentHovered && (
            <div
              className={`${styles.productFooterActions} ${isContentHovered ? styles.visible : ""}`}
            >
              <Popper text="Suggested Price">
                <div className={styles.productSrp}>
                  <div>
                    <span
                      style={{ color: "#246071" }}
                      className={styles.dollarSign}
                    >
                      $
                    </span>
                    <span style={{ color: "#246071" }} className={styles.price}>
                      {selectedVariant?.suggested_cost}
                    </span>
                  </div>
                  <span
                    style={{ color: "#246071" }}
                    className={styles.priceLabel}
                  >
                    SRP
                  </span>
                </div>
              </Popper>
              <div className={styles.productProfit}>
                <div>
                  <span style={{ color: "#FFF" }} className={styles.dollarSign}>
                    $
                  </span>
                  <span style={{ color: "#FFF" }} className={styles.price}>
                    {parseFloat(
                      Number(selectedVariant?.suggested_cost) -
                        Number(selectedVariant?.cost)
                    ).toFixed(2)}
                  </span>
                </div>
                <span style={{ color: "#FFF" }} className={styles.priceLabel}>
                  PROFIT
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;

// for future typescript
// type ProductType = {
//   id: number;
//   name: string;
//   slug: string;
//   description: string;
//   benefits: string;
//   application: string;
//   ingredients: string | null;
//   product_notes: string | null;
// };
