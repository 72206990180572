import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

// MUI components:
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";

// API:
import { getShippingCost } from "api/endpoints/cartApi";

// custom components and constants:
import Button from "components/Buttons/Button";
import AddressAutocomplete from "components/Input/AddressAutocomplete";
import CountryAutocomplete from "components/Input/CountryAutocomplete/CountryAutocomplete";
import Input from "components/Input/Input";
import {
  ACCOUNT_ADDRESSES_FIELDS,
  SHIPPING_METHODS,
} from "constants/accountAddresses";
import { COUNTRIES } from "constants/countries";

// third-party libraries:
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// hooks:
import useAccountAddresses from "hooks/useAccountAddresses";

// styles:
import MobileInput from "components/ui/MobileInput";
import parsePhoneNumberFromString from "libphonenumber-js";
import removeCountryCode from "utils/removeCountryCode";
import styles from "./AccountAddressesForm.module.scss";

// Auxiliary function to transform address data from autocomplete
const sanitize = (str) => str?.replace(/[^a-zA-Z0-9 ]/g, "");

const getCountryPhoneCode = (countryName) => {
  const country = COUNTRIES.find((country) => country.name === countryName);
  return country?.country_code ?? "";
};

export default function AccountAddressesForm(props) {
  const { onSubmit, onCancel, withPhoneNumber } = props;

  const { isEditing, addressToEdit, status } = useSelector(
    (state) => state.accountAddresses
  );

  const { shippingMethod, handleOnSelectShippingMethod } =
    useAccountAddresses();

  // internal state:
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const { control, register, handleSubmit, setValue, watch, setError } =
    useForm({
      country:
        addressToEdit?.country === undefined ? "" : addressToEdit.country,
      default: addressToEdit?.default || false,
      defaultValues: {
        phone_number: removeCountryCode(addressToEdit?.phone) ?? "",
        phone_country_code:
          getCountryPhoneCode(addressToEdit?.country?.name) ?? "",
      },
    });

  const watchDefault = watch("default", false);
  const watchFields = watch([
    "first_name",
    "last_name",
    "address",
    "city",
    "state",
    "zip_code",
  ]);
  const watchCountry = watch("country", "");
  const watchPhone = watch("phone_number", "");
  const watchPhoneCountryCode = watch("phone_country_code", "");

  const isAllFieldsFilled = watchFields.every(
    (fieldValue) => fieldValue && fieldValue !== ""
  );

  // this useEffect is how we maintain the pattern of only enabling the save
  // button when all the required fields are filled out
  useEffect(() => {
    // check fields on custom Input:
    const allFieldsFilled = watchFields.every(
      (fieldValue) => fieldValue && fieldValue !== ""
    );

    // check country on CountryAutocomplete:
    // country data structure: {name: string, code: string}
    const isCountryFilled = watchCountry && watchCountry.name !== "";

    // check if phone number is valid & update the register variable:
    const phoneNumber = parsePhoneNumberFromString(
      `${watchPhoneCountryCode}${watchPhone}`,
      watchCountry.code
    );

    // if allFieldsFilled and isCountryFilled are true, then enable save button
    setIsSaveEnabled(
      allFieldsFilled && isCountryFilled && phoneNumber && phoneNumber.isValid()
    );
  }, [watchFields]);

  useEffect(() => {
    if (
      status === "idle" &&
      isEditing &&
      Object.keys(addressToEdit).length > 0
    ) {
      const transformedData = {
        ...addressToEdit,
        address: addressToEdit.address_one,
        unit: sanitize(addressToEdit.address_two),
      };

      // Update form fields
      Object.keys(transformedData).forEach((key) => {
        if (key === "country") {
          // Assuming country is an object with name and code
          setValue(key, {
            name: transformedData[key].name,
            code: transformedData[key].code,
          });
        } else {
          setValue(key, transformedData[key]);
        }
      });
    }
  }, [status]);

  const { data, isLoading } = useQuery({
    queryKey: "getShippingCost",
    queryFn: () => getShippingCost(watchCountry?.code, watch("zip_code"), 0),
    enabled: !!watchCountry?.code && !!watch("zip_code"),
  });

  const handleAutoCompleteAddress = (addressData) => {
    setValue("address", sanitize(addressData.fullAddress));
    setValue("city", addressData.city);
    setValue("state", addressData.state);
    setValue("zip_code", addressData.zipCode);
    setValue("country", addressData.country);
  };

  if (status !== "idle") {
    return null;
  }

  return (
    <form className={styles.accountAddressesForm}>
      <Stack className={styles.stackContainer}>
        <Box className={styles.twoGrid}>
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.first_name}*`}
            name="first_name"
            control={control}
            defaultValue=""
            rules={{
              required: "First name is required",
            }}
          />
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.last_name}*`}
            name="last_name"
            control={control}
            defaultValue=""
            rules={{
              required: "Last name is required",
            }}
          />
        </Box>
        <Input
          label={ACCOUNT_ADDRESSES_FIELDS.company}
          name="company"
          control={control}
          defaultValue=""
        />

        <AddressAutocomplete
          control={control}
          name="address"
          label="Address*"
          rules={{
            required: "Address is required",
          }}
          handleAutoCompleteAddress={handleAutoCompleteAddress}
        />

        <Box className={styles.oneGrid}>
          <Input
            label={ACCOUNT_ADDRESSES_FIELDS.unit}
            name="unit"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /^[\w\W]*$/i,
                message: "Please enter a unit/suite number",
              },
            }}
          />
        </Box>

        <Box className={styles.threeGrid}>
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.city}*`}
            name="city"
            control={control}
            defaultValue=""
            rules={{
              required: "City name is required",
            }}
          />
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.state}*`}
            name="state"
            control={control}
            defaultValue=""
            rules={{
              required: "State name is required",
            }}
          />
          <Input
            label={`${ACCOUNT_ADDRESSES_FIELDS.zip_code}*`}
            name="zip_code"
            control={control}
            defaultValue=""
            rules={{ required: "Zip code is required" }}
          />
        </Box>

        <div className={styles.autoCompleteContainer}>
          <CountryAutocomplete
            control={control}
            name="country"
            defaultValue=""
            label={`${ACCOUNT_ADDRESSES_FIELDS.country}*`}
            countries={COUNTRIES}
            rules={{
              required: "Country is required",
              validate: (value) =>
                value !== undefined || "Value cannot be undefined",
            }}
          />
        </div>

        <div className={styles.mobileWrapper}>
          <MobileInput
            control={control}
            countryDefault={getCountryPhoneCode(addressToEdit?.country?.name)}
            hideAcceptTerms={withPhoneNumber}
            isEditMode
          />
        </div>

        <FormControlLabel
          control={
            <div className={styles.checkboxWrapper}>
              <Checkbox {...register("default")} checked={watchDefault} />
              <p>Use this as my default shipping address</p>
            </div>
          }
        />

        <Box className={styles.singleLine}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={!isSaveEnabled}
          >
            Save
          </Button>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
