import Button from "components/Buttons/Button";
import ModalWrapper from "components/ModalWrapper";
import PropTypes from "prop-types";
import React from "react";

import circle from "assets/images/brand-plate/circle.svg";
import jar from "assets/images/brand-plate/jar.svg";
import lipStick from "assets/images/brand-plate/lip-stick.svg";
import squares from "assets/images/brand-plate/squares.svg";

import styles from "./SampleOrderSelectType.module.scss";

export default function SampleOrderSelectType({
  open,
  onClose,
  confirmUnbranded,
  confirmBranded,
}) {
  return (
    <ModalWrapper isOpen={open} handleClose={onClose} title="Select Order Type">
      <div className={styles.container}>
        <h3 className={styles.title}>Order sample</h3>
        <p className={styles.description}>
          Test products sooner without your branding or wait to preview your
          fully personalized products.
        </p>

        <div className={styles.actions}>
          <div onClick={confirmBranded} className={styles.card}>
            <span className={styles.cardTitle}>
              WITH
              <br />
              YOUR BRAND
            </span>
            <p className={styles.cardDescription}>
              Shipped within 5-9 business days.
              <br />
              Order limit: maximum 3 per SKU.
            </p>
            <div className={styles.rightImages}>
              <div className={styles.jar}>
                <img className={styles.shapes} src={jar} alt="Jar" />
                <div className={styles.jarSquare} />
              </div>
              <div className={styles.lipStick}>
                <img className={styles.shapes} src={lipStick} alt="Lipstick" />
                <div className={styles.lipStickSquare} />
              </div>
              <div className={styles.circle}>
                <img className={styles.shapes} src={circle} alt="Lipstick" />
                <div className={styles.circleSquare} />
              </div>
              <div className={styles.squares}>
                <img className={styles.shapes} src={squares} alt="Lipstick" />
                <div className={styles.squaresSquare} />
                <div className={styles.squaresSquareTwo} />
              </div>
            </div>
            <Button
              className={styles.cardButton}
              variant="text"
              size="small"
              color="primary"
            >
              Select
            </Button>
          </div>
          <div onClick={confirmUnbranded} className={styles.card}>
            <span className={styles.cardTitle}>
              WITHOUT
              <br />
              YOUR BRAND
            </span>
            <p className={styles.cardDescription}>
              Shipped within 1-2 business days.
            </p>
            <div className={styles.rightImages}>
              <div className={styles.jar}>
                <img className={styles.shapes} src={jar} alt="Jar" />
              </div>
              <div className={styles.lipStick}>
                <img className={styles.shapes} src={lipStick} alt="Lipstick" />
              </div>
              <div className={styles.circle}>
                <img className={styles.shapes} src={circle} alt="Lipstick" />
              </div>
              <div className={styles.squares}>
                <img className={styles.shapes} src={squares} alt="Lipstick" />
              </div>
            </div>
            <Button
              className={styles.cardButton}
              variant="text"
              size="small"
              color="primary"
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
SampleOrderSelectType.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmUnbranded: PropTypes.func.isRequired,
  confirmBranded: PropTypes.func.isRequired,
};

SampleOrderSelectType.defaultProps = {};
