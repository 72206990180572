// we can just potentially have this in the backend to allow marketing to easily update the news section
import sampleImage from "assets/images/brand-plate/blanka-my-logo-drop.svg";

const htmlString = `
  <p>
    <strong>Watch the webinar:</strong> 
    <a target="__blank"href="https://blankabrand.ewebinar.com/webinar/how-to-build-your-beauty-wellness-brand-with-blanka-14218">Getting started with Blanka</a>
    <br />
  </p>
  <p>
    <strong>Need extra help?</strong> 
    <a href="/profile?open_change_plan=true">Upgrade to VIP</a> for a 1-1 onboarding call with one of our Merchant Success Specialists.
    <br />
  </p>
`;

const newProductLaunchesHtml = `
  <p>
    <strong>Check out our recently released products here: </strong>
    <a href="/?category=new">new products</a>
  </p>
`;

export const FOR_DYNAMIC_NEWS_CARDS = [
  {
    title: "Resources",
    content: htmlString,
  },
  {
    image: sampleImage,
    title: "New Product Launches",
    content: newProductLaunchesHtml,
  },
];

export const FOR_DYNAMIC_TASKS = [
  {
    icon: "wand",
    key: "brand_plate_created",
    step: 1,
    title: "Create branded products",
    content: `
  <p>
    <a href="/brand-setup">Complete your brand setup</a> by adding your logo and designing your custom products.
  </p>
`,
    buttonLabel: "GET STARTED",
    buttonPath: "/brand-setup",
  },
  {
    icon: "product",
    key: "count_sample_orders",
    step: 2,
    title: "Try products",
    content: `
    <p>
      <a href="/?category=all">Order samples</a> to test what you like.
    </p>
  `,
    buttonLabel: "ORDER NOW",
    buttonPath: "/?category=all",
  },
  {
    icon: "store",
    key: "count_inventory_orders,count_number_of_custom_products",
    step: 3,
    title: "Start selling",
    content: `
    <p>
      <a href="/?category=all&product_type=CUSTOM">Order branded inventory</a> to yourself or <a href="/?category=all&product_type=CUSTOM">publish products</a> directly to your online store and start making sales!
    </p>
  `,
    buttonLabel: "CONNECT STORE",
    buttonPath: "/profile/connect",
  },
];
