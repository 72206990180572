/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from "react";

import { addProduct, getProductsV2, QUERY } from "api";
import { useMutation, useQuery } from "react-query";

import PageHeader from "components/PageHeader";
import SearchInput from "components/SearchInput";

import { Grid } from "@mui/material";
import Button from "components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTE_PATHS from "routes/routePaths";
import cn from "utils/cn";
import ProductCard from "views/FindProductsView/components/ProductCard/ProductCard";
import ProductCardSkeleton from "views/FindProductsView/components/ProductCardSkeleton/ProductCardSkeleton";
import styles from "./FavoritesView.module.scss";

export default function FavoritesView() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [customIsActive, setCustomIsActive] = useState(false);

  const { isLoading, data, isRefetching } = useQuery(
    [QUERY.getProducts, "favorites", "CUSTOM"],
    () =>
      getProductsV2({
        product_type: "CUSTOM",
        favorites: true,
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const products = useMemo(() => {
    if (!data?.data) return [];
    const cleaned = Array.isArray(data.data) ? data.data : [data.data];

    const favorites = cleaned.map((data) => {
      const productGroup = data?.product_group.filter((group) =>
        group?.categories?.includes("favorites")
      );

      return { ...data, product_group: productGroup };
    });

    if (search) {
      const filtered = favorites.filter((product) => {
        const name = product.product.name.toLowerCase();
        return name.includes(search.toLowerCase());
      });

      return filtered;
    }

    return favorites;
  }, [data, search]);

  const addToStoreMutation = useMutation(addProduct);

  const onAddAllToMyProducts = () => {
    const productIds = products
      .map((product) => product?.product_group?.map((group) => group.id))
      .flat();

    addToStoreMutation.mutate(
      { product_ids: productIds },
      {
        onSuccess: () => {
          toast.success("Added favorites to your products");
        },
      }
    );
  };

  return (
    <div className={styles.favoritesView}>
      <PageHeader title="Favorites" />
      <div
        className={cn(
          styles.favoritesHeader,
          customIsActive && styles.favoritesHeaderActive
        )}
      >
        <SearchInput
          isCollapsable
          customOnChange={(value) => setSearch(value)}
          customIsActive={(isActive) => setCustomIsActive(isActive)}
        />
        <Button
          disabled={
            addToStoreMutation.isLoading || isLoading || products.length === 0
          }
          loading={addToStoreMutation.isLoading}
          size="small"
          variant="outlined"
          color="primary"
          onClick={onAddAllToMyProducts}
        >
          Add all to my products
        </Button>
      </div>

      {isLoading || isRefetching ? (
        <div className={styles.favoritesList}>
          <Grid container spacing={3}>
            <div className={styles.productListContainer}>
              {[...Array(8)].map((_, i) => (
                <ProductCardSkeleton key={`${i + 1}`} />
              ))}
            </div>
          </Grid>
        </div>
      ) : products.length > 0 ? (
        <div>
          <div className={styles.favoritesList}>
            <Grid container spacing={3}>
              <div className={styles.productListContainer}>
                {products.map((product) => (
                  <ProductCard
                    product={product?.product}
                    variants={product?.product_group}
                    key={product.id}
                  />
                ))}
              </div>
            </Grid>
          </div>
        </div>
      ) : (
        <div className={styles.noFavoritesView}>
          <h2>Looking for favorites?</h2>
          <p>Check out all our bestselling products!</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(
                `${ROUTE_PATHS.HOME}?category=bestsellers&product_type=CUSTOM`
              );
            }}
          >
            GO TO BESTSELLERS
          </Button>
        </div>
      )}
    </div>
  );
}
