import React from "react";
import propTypes from "prop-types";

// mui components:
import { Box, Stack } from "@mui/material";

// custom components:
import LowStockTag from "components/Labels/LowStockTag";
import ExpiringTag from "components/Labels/ExpiringTag";
import OutOfStockTag from "components/Labels/OutOfStockTag";
import DiscontinuedTag from "components/Labels/DiscontinuedTag";

// styles:
import styles from "./ProductStatusIndicator.module.scss";

export default function ProductStatusIndicator(props) {
  const { product, isFullWidth, isModal } = props;

  const renderProductTags = () => {
    if (product?.product_base?.is_discontinued) {
      return (
        <Box className={styles.boxWrapper}>
          {/* <DiscontinuedTag expiredDate={product?.product_base?.expires_at} /> */}
        </Box>
      );
    }

    return (
      <Stack
        className={`${styles.stackWrapper} ${isModal && styles.alignRight}`}
      >
        {product?.product_base?.low_stock && (
          <LowStockTag
            text={product?.product_base?.low_stock_message}
            isFullWidth={isModal || isFullWidth}
          />
        )}
        {product?.product_base?.expires_at !== null && (
          <ExpiringTag
            expiryDate={product?.product_base?.expires_at}
            isFullWidth={isModal || isFullWidth}
          />
        )}
        {product?.product_base?.out_of_stock && (
          <OutOfStockTag
            expiryDate={product?.product_base?.out_of_stock_date}
            isFullWidth={isModal || isFullWidth}
          />
        )}
      </Stack>
    );
  };

  return (
    <Box className={styles.productStatusIndicator}>{renderProductTags()}</Box>
  );
}

ProductStatusIndicator.propTypes = {
  product: propTypes.object,
  isFullWidth: propTypes.bool,
  isModal: propTypes.bool,
};

ProductStatusIndicator.defaultProps = {
  product: {},
  isFullWidth: false,
  isModal: false,
};
