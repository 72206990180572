import React from "react";

// MUI Icons
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

// utils
import cn from "utils/cn";

// Icons
import AutopayIcon from "assets/images/orders/autopay.svg";
import AutopayIconError from "assets/images/orders/autopayError.svg";

// Components
import Text from "components/Text";

import styles from "./AutomaticPaymentStatus.module.scss";

export default function AutomaticPaymentStatus({ status }) {
  return (
    <div className={styles.automaticPaymentStatusWrapper}>
      <div
        className={cn(
          styles.automaticPaymentStatus,
          !status ? styles.bgSuccess : styles.bgError
        )}
      >
        <img
          src={!status ? AutopayIcon : AutopayIconError}
          alt={!status ? "Auto payment success" : "Auto payment error"}
        />
        <Text
          className={cn(styles.text, !status ? styles.success : styles.error)}
        >
          {!status
            ? "Processed with Automatic Payment"
            : "Automatic Payment Error"}
        </Text>
      </div>
      {status && (
        <div className={styles.errorMessage}>
          <ErrorOutlineOutlinedIcon />
          <Text className={styles.errorText}>
            We were not able to process your last payment(s), and have turned
            off Automatic Payments. Please update your billing method to avoid
            further service disruptions.
          </Text>
        </div>
      )}
    </div>
  );
}
