import {
  CART_TYPE_SAMPLE,
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  DEBOUNCE_DELAY,
} from "constants/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useCart from "hooks/useCart";
import {
  clearCartDiscount,
  createCartAsyncThunk,
  getTiersByProductIdAsync,
  setCart,
  setSampleOrderDiscount,
  setShippingCart,
  toggleBrandedBoxForAllItems,
  updateCart,
} from "state/cartSlice";

import { SMALL } from "constants/breakpoints";

import Alert from "components/Alert";
import CartProductStatusModal from "components/Modals/CartProductStatusModal";
import SampleOrderSelectType from "components/Modals/SampleOrderSelectType/SampleOrderSelectType";
import UnavailableProductNotification from "components/Notifications/UnavailableProductNotification/UnavailableProductNotification";
import PageHeader from "components/PageHeader";
import { useMediaQuery } from "react-responsive";
import CartDesktopView from "./components/CartDesktopView/CartDesktopView";
import CartMobileView from "./components/CartMobileView/CartMobileView";

import CartEmpty from "./components/CartEmpty";

import "styles/components/breadcrumbs.scss";
import styles from "./CartView.module.scss";

function CartView(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCartProductStatusOpen, setIsCartProductStatusOpen] = useState(false);
  const [
    unavailableProductsNotificationOpen,
    setUnavailableProductsNotificationOpen,
  ] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartHook = useCart();

  const cart = useSelector((state) => state.cart);
  const shop = useSelector((state) => state.profile.shop);
  const [limitError, setLimitError] = useState(false);

  // check if cart has any items that are discontinued or out of stock
  useEffect(() => {
    if (
      cartHook.hasDiscontinuedOrOutOfStock(cart.items) &&
      !unavailableProductsNotificationOpen
    ) {
      setUnavailableProductsNotificationOpen(true);
    }
  }, [cart.items]);

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleContinue = () => {
    navigate("/checkout/shipping");
  };

  const onConfirm = () => {
    setIsOpen(false);
    handleContinue();
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleUpdate = async (item, quantity) => {
    try {
      // Convert the empty string to a suitable value
      let parsedQuantity = quantity === "" ? 1 : parseInt(quantity, 10);

      // Check if the parsedQuantity is NaN (which occurs if the input is not a number)
      // If so, default to a value such as 0 or null
      if (parsedQuantity === 0 || Number.isNaN(parsedQuantity)) {
        parsedQuantity = 1;
      }

      cartHook.updateQuantity(item, parsedQuantity);

      // If the debounced function needs to know the quantity, pass it as an argument,
      // otherwise, you can call it without arguments
      cartHook.debouncedSendCartToServer();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnBlurredInputField = async (item, quantity) => {
    if (quantity === "0") {
      handleUpdate(item, 1);
      return;
    }

    handleUpdate(item, quantity);
  };

  useEffect(() => {
    // Using 1 as productId only because the tiers are for all products so far
    dispatch(getTiersByProductIdAsync({ productId: 1 }));
    dispatch(
      setShippingCart({
        store_at_blanka: true,
        shipping_cost: 0,
      })
    );
  }, []);

  const handleRemoveItem = (itemId) => {
    cartHook.remove(itemId);
  };

  const handleContinueToCheckout = () => {
    setLimitError(false);

    if (cartHook.hasDiscontinuedOrOutOfStock(cart.items)) {
      setIsCartProductStatusOpen(true);
      return;
    }

    if (
      shop.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE &&
      cart.type === CART_TYPE_SAMPLE
    ) {
      setIsOpen(true);
    } else {
      handleContinue();
    }
  };

  const handleConfirmUnbranded = () => {
    // Update all SKUs to be unbranded by removing any prefix before the first "-"
    const updatedItems = cart.items.map((item) => {
      const updatedSku = item.sku.replace(/^\d+-/, "");
      return {
        ...item,
        sku: updatedSku,
        product: { ...item?.product, product_type: "BLANK" },
      };
    });

    // Update the cart in the Redux state with the new unbranded SKUs
    dispatch(setCart({ ...cart, items: updatedItems }));
    cartHook.debouncedSendCartToServer();
    setIsOpen(false);
    handleContinue();
  };

  const handleConfirmBranded = () => {
    let block = false;

    // Add the shop ID as a prefix to all SKUs if not already present
    const updatedItems = cart.items.map((item) => {
      // Ensure the SKU isn't already branded (i.e., doesn't already start with shop.id)
      const updatedSku = item.sku.startsWith(`${shop.id}-`)
        ? item.sku
        : `${shop.id}-${item.sku}`;

      // we only allow max 3 of branded samples
      const isLimit = item?.quantity > 3;

      if (isLimit) {
        setLimitError(true);

        block = true;
      }

      return {
        ...item,
        sku: updatedSku,
        quantity: isLimit ? 3 : item?.quantity,
        product: { ...item?.product, product_type: "CUSTOM" },
      };
    });

    // Update the cart in the Redux state with the new branded SKUs
    dispatch(setCart({ ...cart, items: updatedItems }));

    if (block) {
      cartHook.debouncedSendCartToServer();
      setIsOpen(false);
    } else {
      handleContinue();
    }
  };

  if (cart.items.length === 0) {
    return <CartEmpty />;
  }

  return (
    <>
      {unavailableProductsNotificationOpen && (
        <UnavailableProductNotification
          handleClose={() => setUnavailableProductsNotificationOpen(false)}
        />
      )}
      <div className={`cart-template ${styles.cartView}`}>
        {!isMobile && <PageHeader title="My Shopping Cart" />}

        {limitError && (
          <Alert
            text="You've reached the maximum order limit for branded samples on one or more products."
            description="We've adjusted the quantities in your cart accordingly."
            variant="warning"
          />
        )}
        {!isMobile && (
          <CartDesktopView
            cart={cart}
            handleUpdate={handleUpdate}
            handleRemoveItem={handleRemoveItem}
            handleContinueToCheckout={handleContinueToCheckout}
          />
        )}

        {isMobile && (
          <CartMobileView
            cart={cart}
            handleUpdate={handleUpdate}
            handleRemoveItem={handleRemoveItem}
            handleContinueToCheckout={handleContinueToCheckout}
            handleOnBlurredInputField={handleOnBlurredInputField}
          />
        )}

        <CartProductStatusModal
          open={isCartProductStatusOpen}
          handleClose={() => setIsCartProductStatusOpen(false)}
        />
      </div>

      {isOpen && (
        <SampleOrderSelectType
          open={isOpen}
          onClose={onClose}
          confirmUnbranded={handleConfirmUnbranded}
          confirmBranded={handleConfirmBranded}
        />
      )}
    </>
  );
}

export default CartView;
